
interface Props {
  setStep?: any;
  content:string;
  imgStr:string;
  step?:number,
  onClick:any
}

export const SectionBack =({imgStr, onClick, content}:Props)=>{

    return (
      <div className="  bg-[#EFF6EE] px-5 min-h-[50px] sm:px-10 xl:px-20 space-y-1 pt-24  pb-4  sm:pb-10  ">
        <div
          className="flex items-center  cursor-pointer"
          onClick={onClick}
        >
          <img src="/images/arrow-back.svg" alt="" />
          <p className="text-black text-[16px]">Back</p>
        </div>
        <div className="flex justify-between  items-center">
          <h3 className="text-textColor font-ppmigra text-[20px] sm:text-[24px] md:text-[36px]">
           {content}
          </h3>
          <div className=" ">
            <img
              src={imgStr}
              className="md:h-[109px] md:w-[216px]  sm:h-[80px] sm:w-[190px] w-[100px] h-[60px]"
              alt=""
            />
          </div>
        </div>
      </div>
    );
}