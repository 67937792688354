import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const importApi = createApi({
  reducerPath: "importApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    credentials: "same-origin",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
     getItemsCategory: builder.query<any, void>({
      query: (body) => ({
        url: `v2/itemCategories`,
        method: 'GET',
        body
        
      }
      ),
  }),
     getImportLocations: builder.query<any, void>({
      query: () => ({
        url: `v2/importLocations`,
        method: 'GET',
        
      }
      ),
  }),

  }),
});

export const { useGetItemsCategoryQuery, useGetImportLocationsQuery } = importApi;
