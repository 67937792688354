interface Props {
  onClick: any;
  isImport?: boolean;
  lasrra?: boolean;
}

export const InformationLayout = ({ onClick, isImport, lasrra }: Props) => {
  return (
    <div className="max-w-[350px] border border-lightGreen rounded-[8px] bg-[#E8FFE5] p-3">
      <div className="flex space-x-1 items-center mb-1">
        <img src="/images/info.svg" className="h-3 w-3" alt="" />
        <p className="text-textColor text-[14px] font-onestBold">Please note</p>
      </div>
      
      {lasrra && (
        <p className="text-[14px] font-onest text-textColor mb-1">
          Before scheduling your LASRRA card delivery, ensure your card is ready
          by dialing <span className="font-onestBold"> *347*641#.</span> using
          the phone number registered with LASRRA.
        </p>
      )}

      {lasrra ? (
        <p className="text-[14px] font-onest text-textColor">
          By clicking on the 'Ship Now' button, you are acknowledging acceptance
          of our
          <span className="underline ml-1 cursor-pointer" onClick={onClick}>
            Terms and Conditions
          </span>
        </p>
      ) : (
        <p className="text-[14px] font-onest text-textColor">
          By completing the {isImport ? "Import" : "Ship Now"} form, you have
          accepted our
          <span className="underline ml-1 cursor-pointer" onClick={onClick}>
            Terms and Conditions.
          </span>
        </p>
      )}
    </div>
  );
};
