import React from "react";
import { Button } from "../components/Button";

export const NotFound = () => {
  return (
    <div className=" h-screen items-center flex flex-col justify-center my-auto">
      <div className=" flex flex-col items-center justify-center">
        <p className="font-onestMedium text-3xl mb-5">404</p>
        <p className="font-onestMedium text-lg mb-5">PAGE NOT FOUND</p>
      </div>
      <Button
        title="Home"
        className="!px-10 text-white"
        onClick={() => (window.location.href = "https://www.fezdelivery.co/")}
      />
    </div>
  );
};
