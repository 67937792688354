/* eslint-disable */
import { useEffect, useState } from "react";
import { PageLayout } from "../components/Layout";
import "animate.css";
import { TrackCard } from "../components/TrackCard";
import { useUpdateOrderStatusMutation } from "../services/slices/track";
import { useNavigate } from "react-router-dom";

const TrackDelivery = ({ setStep, dataDetails, setDataDetails}:any) => {
  const [trackNumber, setTrackNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [updateOrderStatus, { isLoading, data, isError }] =
    useUpdateOrderStatusMutation();

    const navigate = useNavigate()
  const getTrackDetails = async () => {
    setErrorMessage("");
    setStep(1)
    if (trackNumber) {
      try {
        await updateOrderStatus({ orderId: trackNumber });
      } catch (error) {}
    }
  };


  useEffect(() => {
    if (data && data?.status !== "Error" ) {
     navigate(`/track-delivery/${trackNumber}`)
      setDataDetails(data);
    } else if (data && data?.status !== "Error") {
      setDataDetails(data);
    } else if (data && data?.status === "Error") {
      const timer = setTimeout(() => {
        setErrorMessage(data?.description);
      }, 2000); 

      return () => clearTimeout(timer);
    }
  }, [data]);

  useEffect(() => {
    if (isError && trackNumber !== "") {
      setTrackNumber("");
      setTrackNumber("");
      const timer = setTimeout(() => {
        setErrorMessage("Network Error");
      }, 2000); 

      return () => clearTimeout(timer);
    }
  }, [isError]);

  return (
    <PageLayout>
      <div className=" mb-32  overflow-x-hidden ">
        <div className="flex bg-[#EFF6EE] flex-col xl:space-y-0   px-5 min-h-[750px] sm:px-10 xl:px-20 md:space-x-5 pt-32 sm:pt-40  pb-20 sm:pb-22 ">
          <div className="lg:mb-10">
            <h2 className="xl:text-[89px] text-center lg:text-start font-ppmigra md:text-[70px] lg:text-[80px]  text-[35px] sm:text-[50px] whitespace-nowrap text-textColor font-bold">
              Track your shipment
            </h2>
          </div>

          <div className="flex lg:flex-row flex-col-reverse items-start lg:space-y-0 space-y-5 justify-center lg:space-x-10 xl:space-x-32 lg:justify-between w-full">
            <div className="min-w-[35%] w-full  mt-5">
              {errorMessage !== "" && (
                <div className="bg-[#f19797] mb-5 border-[red]  border  px-5 py-2 rounded-md">
                  <p className="text-white items-center flex text-[14px]  font-onestMedium">
                    {errorMessage}
                  </p>
                </div>
              )}
              <TrackCard
                value={trackNumber}
                onChange={(e: any) => setTrackNumber(e.target.value)}
                onTrackDetails={getTrackDetails}
                isLoading={isLoading}
              />
              <div className="mt-10 animate__animated animate__fadeInRight ">
                <img
                  src="/images/bike-rope.svg"
                  className="lg:min-h-[350px] w-full"
                  alt=""
                />
              </div>
            </div>
            <div className="animate__animated animate__bounceIn  w-full min-w-[55%] ">
              <img src="/images/map.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center px-4 sm:px-10 xl:px-20 pt-16 cursor-pointer"  onClick={() =>
                  (window.location.href = "https://www.fezdelivery.co/festive-discounts")
                }>
          <img src="/images/Banner 2.svg" alt="banner-icon" className="w-full"  />
        </div>
      </div>
    </PageLayout>
  );
};

export default TrackDelivery;
