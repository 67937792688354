import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

  interface Props{
    zone: string,  
  }

  interface LockProps{
    lockerID: string,
  }
export const lockerApi = createApi({
  reducerPath: "lockerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    credentials: "same-origin",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLockers: builder.mutation<any, Props>({
        query: (body) => ({
          url: `v2/getLockers`,
          method: 'POST',
          body
          
        }
        ),
    }),
    
    getLockerAvailability: builder.mutation<any, LockProps>({
      query: (body) => ({
        url: `v2/getLockerAvailability`,
        method: 'POST',
        body
        
      }
      ),
    }),

  }),
});

export const { useGetLockersMutation, useGetLockerAvailabilityMutation } = lockerApi;
