import React from "react";

interface TextAreaProps {
  label?: string;
  placeholder?: string;
  labelFor?: string;
  type?: string;
  name?: string;
  value?: string;
  onChange?: any;
  onFocus?: any;
  isRequired?: boolean;
}
export const TextArea = ({
  labelFor,
  placeholder,
  label,
  onChange,
  name,
  value,
  onFocus,
  isRequired
}: TextAreaProps) => {
  return (
    <div className="flex flex-col">
      <label
        htmlFor={labelFor}
        className="text-[#000000] items-center flex text-[14px]  font-onestMedium"
      >
        {label}
        {isRequired && <span className="text-red-500 text-[20px]">*</span>}
      </label>
      <textarea
        rows={4}
        name={name}
        value={value}
        onFocus={onFocus} 
        onChange={onChange}
        placeholder={placeholder}
        className="text-grey p-2 text-[14px] cursor-text border-2 border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full"
      />
    </div>
  );
};
