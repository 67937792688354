import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


interface AuthProps{
 clientsPhone:string,
 clientsPassword: string,
 clientsID:number
}

export interface getAccountProps {
      status: string,
    description: string,
    authDetails: {
        authToken: string,
        expireToken: string
    },
    clientDetails: any,
    usingTempPassword: string
}

export const auth = createApi({
  reducerPath:"auth",
  baseQuery: fetchBaseQuery({ 
    baseUrl: `${process.env.REACT_APP_URL}v1/client/`,
    
 }),
  endpoints: (builder) => ({
   getAccount: builder.mutation<getAccountProps, AuthProps>({
      query: (body) => ({
        url: `getAccounts`,
        method: 'POST',
        body
        
      }
      ),
  }),
   authenticateClient: builder.mutation<getAccountProps, AuthProps>({
      query: (body) => ({
        url: `authenticate`,
        method: 'POST',
        body
        
      }
      ),
  }),
  
})
})

export const { useAuthenticateClientMutation, useGetAccountMutation} = auth