import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const trackApi = createApi({
  reducerPath: "trackApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}v1/`,
    credentials: "same-origin",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    updateOrderStatus: builder.mutation<any, { orderId: string }>({
      query: ({ orderId,  }) => ({
        url: `order/historyv2/${orderId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useUpdateOrderStatusMutation } = trackApi;