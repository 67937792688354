import React, { RefObject, useEffect } from "react";
interface InputProps {
  label?: string;
  placeholder?: string;
  labelFor?: string;
  className?: string;
  type?: string;
  name?: string;
  id?: string;
  onChange?: any;
  onFocus?: any;
  value?: any;
  weightShow?: boolean;
  date?: boolean;
  file?: string;
  disabled?: boolean;
  isRequired?: boolean;
  accept?:string;
}

export const Input = ({
  labelFor,
  placeholder,
  className,
  weightShow,
  id,
  type,
  name,
  label,
  value,
  onChange,
  disabled,
  date,
  onFocus,
  isRequired,
  file,
  accept
}: InputProps) => {
const uniqueAutoComplete = `new-password-${Math.random()}`;
  const input = document.getElementById("datepicker");
  if (input) {
    input.addEventListener("click", () => {
      input.setAttribute("type", "date");
    });
  }

const inputRef: RefObject<HTMLInputElement> = React.createRef();
useEffect(() => {
  if (inputRef.current !== null) {
    inputRef.current.value = '';
  }
}, [inputRef]);
  return (
    <div className="flex flex-col w-full">
      <label
        htmlFor={labelFor}
        className="text-[#000000] items-center flex text-[14px]  font-onestMedium"
      >
        {label}
        {isRequired && <span className="text-red-500 text-[20px]">*</span>}
      </label>

      {!weightShow && (
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          name={name}
          autoComplete={uniqueAutoComplete}
          accept={accept}
          className={`text-textColor p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2 ${className} border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full`}
        />
      )}
      {weightShow && (
        <div className="flex border-2 border-inputBorderGreen  focus:border-2 rounded-[8px] focus:border-inputBorderGreen space-x-1 items-center">
          <input
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            name={name}
            autoComplete="off"
            className={`text-textColor p-2 text-[14px] cursor-text ${className} outline-0 focus:outline-none focus:ring-0   focus:border-0 border-0   bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full`}
          />
          <div className=" px-3 py-2 bg-inputBorderGreen">
            <p className="text-grey">kg</p>
          </div>
        </div>
      )}
      {date && (
        <>
          <label htmlFor="date-input" className="">
            Select a date
          </label>
          <input
            id="datepicker"
            type={type}
            onFocus={onFocus}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            disabled={true}
            name={name}
            className={`text-grey  p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2 ${className} border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full`}
          />
        </>
      )}
      {file && 
      <span 
      className="text-[#000000] items-center flex text-[14px]  font-onestMedium"
      >File Name: {file}</span>
      }
    </div>
  );
};
