
export const devTerms= `
Acceptance of Terms:
By utilising the services provided by FEZ Delivery, hereafter referred to as "the Company," users explicitly acknowledge and agree to be bound by the terms and conditions outlined herein. The Company reserves the right to revise these terms periodically without prior notice.

Shipments and Freight:
The Company operates as a facilitator for shipping and freight services from the United Kingdom to Nigeria. Users are responsible for ensuring their shipments comply with all relevant customs and import regulations to mitigate potential liabilities for the Company.

Collection and Delivery:
Items will be either collected from the Company's Lagos office in Nigeria or delivered to the customer's designated location, with specific details communicated upon order confirmation.

Shipment Restrictions:
The Company retains absolute discretion to refuse the shipment of certain items, including prohibited or restricted goods, perishable items, hazardous materials, and those exceeding specified weight and size limits.

Customs and Duties:
Customers bear full responsibility for any customs duties, taxes, or additional charges imposed by Nigerian authorities. The Company will provide necessary documentation to facilitate customs clearance, but any resultant costs or consequences are the sole responsibility of the customer.

Packaging:
Customers assume full responsibility for securely packaging their items for transportation. The Company disclaims liability for damage to improperly packaged goods.

Tracking and Notifications:
The Company provides tracking services for shipment monitoring, with customers receiving notifications regarding the progress and delivery of their items. However, the Company does not guarantee real-time accuracy or completeness.

Delivery Charges:
Delivery charges are calculated based on the weight, dimensions, and destination of the shipment. The Company reserves the right to adjust charges as necessary without prior notice.




Payment:
Payment for the Company's services is mandatory in advance, covering shipping, handling, and customs processing costs. Failure to remit payment may result in the suspension of services.

Liability and Insurance:
The Company assumes no liability for the loss, damage, or delay of shipments unless directly attributable to its negligence. Customers are encouraged to procure additional insurance for comprehensive coverage.

Cancellation and Refunds:
Orders may be cancelled before dispatch, subject to applicable fees outlined in the Company's refund policy.

Force Majeure:
The Company is absolved from liability for delays or the failure to fulfil obligations due to unforeseen circumstances beyond its reasonable control, including acts of nature, strikes, or government regulations.

Governing Law:
These terms and conditions are governed by and construed in accordance with the laws of Nigeria. Any disputes will be exclusively adjudicated by the courts of Nigeria.

Customer Responsibilities:
Customers must provide accurate and complete information when placing orders, including contact details and shipment information. The Company disclaims responsibility for issues arising from incorrect or incomplete information provided by the customer.

Prohibited Items:
Customers are expressly prohibited from shipping items contravening local laws, international regulations, or Company policies. A comprehensive list of prohibited items is available on the Company's official website.

Storage Fees:
In the event a customer fails to collect their shipment within the specified timeframe, the Company reserves the right to impose storage fees. Non-payment may result in the disposal or return of items at the customer's expense.

Inspection of Shipments:
The Company retains the right to inspect shipments for compliance with shipping regulations and internal policies, including the opening of packages for inspection purposes.

Electronic Communication:
Customers provide explicit consent to receive electronic communications from the Company, encompassing notifications, updates, and promotional information. Opting out of promotional communications is available at any time.

Limitation of Liability:
The Company's liability is confined to the lesser of the declared value of the shipment or the actual repair or replacement cost. In no event shall the Company be held liable for consequential, indirect, or special damages.

Dispute Resolution:
Any disputes or claims arising out of or in connection with these terms and conditions will be subject to negotiation in good faith. If a resolution is not reached, the parties agree to pursue mediation or arbitration as outlined in the Company's dispute resolution policy.

Privacy Policy:
The Company upholds rigorous privacy standards. The privacy policy, accessible on the Company's official website, elucidates how customer information is collected, used, and safeguarded.

Severability:
If any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions will remain valid and enforceable to the fullest extent permitted by law.

Amendments:
The Company reserves the right to amend these terms and conditions at any time. Customers will be duly notified of such changes, and continued use of the Company's services implies acceptance of the modified terms.

Entire Agreement:
These terms and conditions constitute the entire agreement between the customer and the Company, superseding any prior agreements, whether written or oral.

Non-Delivery and Returned Shipments:
In instances where a shipment cannot be delivered due to factors beyond the Company's control, such as an incorrect address, the customer will be notified. Additional charges may apply for re-delivery attempts or return shipping.

Customer Communication:
Customers are required to promptly respond to the Company's communications regarding their shipments, including providing any necessary documentation for customs clearance.

Electronic Signature:
The customer acknowledges that electronic confirmations, including electronic signatures, may be used as evidence of delivery or receipt of goods.

Lost or Damaged Items:
Customers are obligated to report any lost or damaged items to the Company within a specified timeframe from the date of delivery. Claims submitted outside this timeframe may not be considered.

Delivery to Doorstep:
For items delivered to the customer's doorstep, the customer must ensure the availability of a responsible party to receive the delivery during the agreed-upon timeframe. The Company is not liable for loss or damage if delivery is made in accordance with customer instructions.

Holiday and Peak Season Surcharges:
Additional charges may apply during peak seasons or holidays due to increased demand or operational challenges. Customers will be informed of any applicable surcharges before confirming their order.

Third-Party Services:
The Company may engage third-party services, such as customs brokers or last-mile delivery partners, to fulfil certain aspects of the delivery process. Customers agree to abide by the terms and conditions of these third-party services.

Currency Conversion:
Charges and fees are quoted in the currency agreed upon at the time of order placement. Currency conversion fees may apply, and customers are responsible for any additional charges incurred.

Right to Refuse Service:
The Company reserves the right to refuse service to any customer or shipment deemed to pose a risk or violate company policies.

Customer Feedback:
Customers are encouraged to provide feedback on their experience with the Company. Feedback may be used for improvement purposes and may be shared on the Company's website or marketing materials with customer consent.

Emergency Situations:
In the event of an emergency or force majeure, such as natural disasters or civil unrest, the Company may temporarily suspend services or alter delivery schedules. Customers will be notified of any such changes.

Anti-Fraud Measures:
The Company employs robust anti-fraud measures to safeguard against unauthorized transactions. Customers may be required to provide additional verification information to prevent fraudulent activity.

User Account Security:
Customers are solely responsible for maintaining the confidentiality of their FEZ Delivery account credentials. Any activity occurring under the customer's account is their exclusive responsibility.

Service Availability:
While the Company strives to provide continuous and reliable services, it disclaims liability for interruptions, delays, or unavailability due to factors beyond its control, such as technical issues, maintenance, or unforeseen circumstances.

Intellectual Property:
All intellectual property rights related to the FEZ Delivery website, logo, and other materials are the exclusive property of the Company. Customers expressly agree not to use or reproduce these materials without explicit permission.

Governing Language:
In case of any inconsistencies or discrepancies between translated versions of these terms and the English version, the English version shall prevail.

Termination of Services:
The Company reserves the right to terminate or suspend services to any customer at its discretion, especially in cases of repeated violations of the terms and conditions.

Indemnification:
Customers expressly agree to indemnify and hold the Company, its employees, and affiliates harmless from any claims, damages, or liabilities arising out of the customer's use of FEZ Delivery services.

Data Protection:
The Company is unwavering in its commitment to protecting customer data. The company's data protection and privacy policy delineate how customer information is collected, used, and secured.

Communication Preferences:
Customers maintain the ability to manage their communication preferences, including opting out of promotional communications, through their account settings on the Company's official website.

Promotional Offers:
The Company may extend promotional discounts or special offers from time to time. The terms and conditions of such promotions will be clearly communicated, with specific conditions potentially applying.

Amendments Notified:
Customers will be duly notified of any significant changes to these terms and conditions. The continued use of FEZ Delivery services after receiving notice of changes implies acceptance of the modified terms.

Social Media Interaction:
The Company may engage with customers through social media platforms. Customers are urged to review and adhere to the terms and conditions of these platforms.

Customer Support:
Customers can avail themselves of the Company's customer support for assistance with inquiries, complaints, or feedback. Contact information is readily available on the Company's official website.

Dangerous Goods Clause:

The shipment of dangerous goods, including but not limited to new smartphones, laptops, kids' new tablets, used smartphones and laptops, aerosols, industrial solvents and liquids, and perfumes, is subject to additional scrutiny and associated costs. Customers intending to ship such items must adhere to strict regulations and may incur extra fees due to their hazardous nature. The Company reserves the right to refuse or apply additional charges for handling and transportation of dangerous goods, as stipulated in our policy on hazardous materials, available on our official website.

By engaging with FEZ Delivery's services, users unequivocally acknowledge that they have diligently read, comprehended, and agreed to abide by these terms and conditions.
`

