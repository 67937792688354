import { useState } from "react";
import { NavBar } from "./NavBar";
import { SideBar } from "./SideBar";
import { Footer } from "./Footer";
import PageFrame from "./PageFrame";

interface Props {
  children: any;
  isLoading?: boolean;
}

export const PageLayout = ({ children, isLoading }: Props) => {
  const [nav, setNav] = useState(false);
  const [navbar, setNavbar] = useState(false);
  return (
    <div className=" w-full h-[100vh] ">
      <div className="fixed  top-0 z-[1000] w-full">
        <NavBar
          hasSet={(e: any) => setNav(e)}
          navbar={navbar}
          setNavbar={setNavbar}
        />
      </div>
        <PageFrame isLoading={isLoading}>
          <div className="px-0 w-full">{children}</div>
        </PageFrame>
        <div>
          <SideBar
            open={nav}
            hasSet={(e: any) => setNav(e)}
            navbar={navbar}
            setNavbar={setNavbar}
          />
        </div>
        <div className="w-full bg-[white]">
          <Footer />
        </div>
    </div>
  );
};
