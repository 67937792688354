import React from "react";
import { PageLayout } from "../components/Layout";

export const Privacy = () => {
  return (
    <PageLayout >
      <div className="p-8 mt-10 space-y-3 text-textColor font-bold bg-[#EFF6EE]">
        <h2 className="text-center text-tex font-onestMedium leading-10 lg:text-[60px] text-[40px] my-12">
          Privacy and Policy
        </h2>
        <p>
          We respect your privacy and are committed to protecting it through our
          compliance with this privacy policy (“Policy”).
        </p>
        <p>
          This Policy describes the types of information we may collect from you
          or that you may provide (“Personal Information”) in the “Fez Delivery
          Riders App” mobile application (“Mobile Application” or “Service”) and
          any of its related products and services (collectively, “Services”),
          and our practices for collecting, using, maintaining, protecting, and
          disclosing that Personal Information.
        </p>
        <p>
          It also describes the choices available to you regarding our use of
          your Personal Information and how you can access and update it. This
          Policy is a legally binding agreement between you (“User”, “you” or
          “your”) and Fez Delivery Co (“Fez Delivery Co”, “we”, “us” or “our”).
        </p>
        <p>
          If you are entering into this Policy on behalf of a business or other
          legal entity, you represent that you have the authority to bind such
          entity to this Policy, in which case the terms “User”, “you” or “your”
          shall refer to such entity. If you do not have such authority, or if
          you do not agree with the terms of this Policy, you must not accept
          this Policy and may not access and use the Mobile Application and
          Services. By accessing and using the Mobile Application and Services,
          you acknowledge that you have read, understood, and agree to be bound
          by the terms of this Policy.
        </p>
        <p>
          This Policy does not apply to the practices of companies that we do
          not own or control, or to individuals that we do not employ or manage.
          Collection of information Our top priority is customer data security
          and, as such, we exercise the no logs policy. We may process only
          minimal user data, only as much as it is absolutely necessary to
          maintain the Mobile Application and Services. Information collected
          automatically is used only to identify potential cases of abuse and
          establish statistical information regarding the usage of the Mobile
          Application and Services.
        </p>
        <p>
          This statistical information is not otherwise aggregated in such a way
          that would identify any particular user of the system. Privacy of
          children We do not knowingly collect any Personal Information from
          children under the age of 18. If you are under the age of 18, please
          do not submit any Personal Information through the Mobile Application
          and Services. If you have reason to believe that a child under the age
          of 18 has provided Personal Information to us through the Mobile
          Application and Services, please contact us to request that we delete
          that child’s Personal Information from our Services.
        </p>
        <p>
          We encourage parents and legal guardians to monitor their children’s
          Internet usage and to help enforce this Policy by instructing their
          children never to provide Personal Information through the Mobile
          Application and Services without their permission. We also ask that
          all parents and legal guardians overseeing the care of children take
          the necessary precautions to ensure that their children are instructed
          to never give out Personal Information when online without their
          permission. Use and processing of collected information We act as a
          data controller and a data processor when handling Personal
          Information, unless we have entered into a data processing agreement
          with you in which case you would be the data controller and we would
          be the data processor.
        </p>
        <p>
          Our role may also differ depending on the specific situation involving
          Personal Information. We act in the capacity of a data controller when
          we ask you to submit your Personal Information that is necessary to
          ensure your access and use of the Mobile Application and Services. In
          such instances, we are a data controller because we determine the
          purposes and means of the processing of Personal Information. We act
          in the capacity of a data processor in situations when you submit
          Personal Information through the Mobile Application and Services. We
          do not own, control, or make decisions about the submitted Personal
          Information, and such Personal Information is processed only in
          accordance with your instructions. In such instances, the User
          providing Personal Information acts as a data controller. In order to
          make the Mobile Application and Services available to you, or to meet
          a legal obligation, we may need to collect and use certain Personal
          Information. If you do not provide the information that we request, we
          may not be able to provide you with the requested products or
          services. Any of the information we collect from you may be used to
          help us run and operate the Mobile Application and Services.
        </p>
        <p>
          <ul>
            Processing your Personal Information depends on how you interact
            with the Mobile Application and Services, where you are located in
            the world and if one of the following applies:
            <li>
              {" "}
              (i) you have given your consent for one or more specific purposes;
            </li>
            <li>
              {" "}
              (ii) provision of information is necessary for the performance of
              this Policy with you and/or for any pre-contractual obligations
              thereof;
            </li>
            <li>
              {" "}
              (iii) processing is necessary for compliance with a legal
              obligation to which you are subject;
            </li>
            <li>
              {" "}
              (iv) processing is related to a task that is carried out in the
              public interest or in the exercise of official authority vested in
              us;{" "}
            </li>
            <li>
              {" "}
              (v) processing is necessary for the purposes of the legitimate
              interests pursued by us or by a third party. Note that under some
              legislations we may be allowed to process information until you
              object to such processing by opting out, without having to rely on
              consent or any other of the legal bases.{" "}
            </li>
          </ul>
        </p>
        <p>
          In any case, we will be happy to clarify the specific legal basis that
          applies to the processing, and in particular whether the provision of
          Personal Information is a statutory or contractual requirement, or a
          requirement necessary to enter into a contract. Disclosure of
          information Depending on the requested Services or as necessary to
          complete any transaction or provide any Service you have requested, we
          may share your information with our affiliates, contracted companies,
          and service providers (collectively, “Service Providers”) we rely upon
          to assist in the operation of the Mobile Application and Services
          available to you and whose privacy policies are consistent with ours
          or who agree to abide by our policies with respect to Personal
          Information. We will not share any personally identifiable information
          with third parties and will not share any information with
          unaffiliated third parties. Service Providers are not authorized to
          use or disclose your information except as necessary to perform
          services on our behalf or comply with legal requirements.
        </p>
        <p>
          Service Providers are given the information they need only in order to
          perform their designated functions, and we do not authorize them to
          use or disclose any of the provided information for their own
          marketing or other purposes. Retention of information We will retain
          and use your Personal Information for the period necessary to comply
          with our legal obligations, to enforce our Policy, resolve disputes,
          and unless a longer retention period is required or permitted by law.
          We may use any aggregated data derived from or incorporating your
          Personal Information after you update or delete it, but not in a
          manner that would identify you personally. Once the retention period
          expires, Personal Information shall be deleted. Therefore, the right
          to access, the right to erasure, the right to rectification, and the
          right to data portability cannot be enforced after the expiration of
          the retention period. Push notifications We offer push notifications
          to which you may voluntarily subscribe at any time.
        </p>
        <p>
          To make sure push notifications reach the correct devices, we use a
          third-party push notifications provider who relies on a device token
          unique to your device which is issued by the operating system of your
          device. While it is possible to access a list of device tokens, they
          will not reveal your identity, your unique device ID, or your contact
          information to us or our third-party push notifications provider. We
          will maintain the information sent via e-mail in accordance with
          applicable laws and regulations. If, at any time, you wish to stop
          receiving push notifications, simply adjust your device settings
          accordingly. Links to other resources The Mobile Application and
          Services contain links to other resources that are not owned or
          controlled by us. Please be aware that we are not responsible for the
          privacy practices of such other resources or third parties. We
          encourage you to be aware when you leave the Mobile Application and
          Services and to read the privacy statements of each and every resource
          that may collect Personal Information. Information security We secure
          information you provide on computer servers in a controlled, secure
          environment, protected from unauthorized access, use, or disclosure.
          We maintain reasonable administrative, technical, and physical
          safeguards in an effort to protect against unauthorized access, use,
          modification, and disclosure of Personal Information in our control
          and custody. However, no data transmission over the Internet or
          wireless network can be guaranteed.
        </p>
        <p>
          <ul>
            Therefore, while we strive to protect your Personal Information, you
            acknowledge that
            <li>
              (i) there are security and privacy limitations of the Internet
              which are beyond our control;
            </li>
            <li>
              {" "}
              (ii) the security, integrity, and privacy of any and all
              information and data exchanged between you and the Mobile
              Application and Services cannot be guaranteed; and
            </li>
            <li>
              {" "}
              (iii) any such information and data may be viewed or tampered with
              in transit by a third party, despite best efforts.{" "}
            </li>
          </ul>
        </p>
        <p>
          Data breach In the event we become aware that the security of the
          Mobile Application and Services has been compromised or Users’
          Personal Information has been disclosed to unrelated third parties as
          a result of external activity, including, but not limited to, security
          attacks or fraud, we reserve the right to take reasonably appropriate
          measures, including, but not limited to, investigation and reporting,
          as well as notification to and cooperation with law enforcement
          authorities. In the event of a data breach, we will make reasonable
          efforts to notify affected individuals if we believe that there is a
          reasonable risk of harm to the User as a result of the breach or if
          notice is otherwise required by law. When we do, we will send you an
          email.
        </p>
        <p>
          Changes and amendments We reserve the right to modify this Policy or
          its terms related to the Mobile Application and Services at any time
          at our discretion. When we do, we will send you an email to notify
          you. We may also provide notice to you in other ways at our
          discretion, such as through the contact information you have provided.
          An updated version of this Policy will be effective immediately upon
          the posting of the revised Policy unless otherwise specified. Your
          continued use of the Mobile Application and Services after the
          effective date of the revised Policy (or such other act specified at
          that time) will constitute your consent to those changes. However, we
          will not, without your consent, use your Personal Information in a
          manner materially different than what was stated at the time your
          Personal Information was collected.
        </p>
        <p>
          Acceptance of this policy You acknowledge that you have read this
          Policy and agree to all its terms and conditions. By accessing and
          using the Mobile Application and Services and submitting your
          information you agree to be bound by this Policy. If you do not agree
          to abide by the terms of this Policy, you are not authorized to access
          or use the Mobile Application and Services. This privacy policy has
          been created with the help of the privacy policy generator.
        </p>
        <p>
          Contacting us If you have any questions, concerns, or complaints
          regarding this Policy, the information we hold about you, or if you
          wish to exercise your rights, we encourage you to contact us using the
          details below: info@fezdelivery.co We will attempt to resolve
          complaints and disputes and make every reasonable effort to honor your
          wish to exercise your rights as quickly as possible and in any event,
          within the timescales provided by applicable data protection laws.
          This document was last updated on November 2, 2023
        </p>
      </div>
    </PageLayout>
  );
};
