import { Loader } from "./Loader";

interface ButtonProps {
  active?: boolean;
  loading?: boolean;
  image?: string;
  title?: string;
  className?: string;
  type?:"submit"|"button";
  onClick?: any;
  disabled?: boolean;
}
export const Button = ({
  className,
  disabled,
  loading,
  title,
  onClick,
  type="button",
}: ButtonProps) => {
  return (
    <div>
      <button
        type={type}
        className={`${className} cursor-pointer bg-btnColor flex justify-center text-textColor rounded-[4px] items-center space-x-1 px-2 sm:px-4 py-2 w-full `}
        onClick={onClick}
        disabled={disabled}
      >
        {loading ? (
          <Loader />
        ) : (
          <p className={`text-[14px] text-center font-onestBold`}>{title}</p>
        )}
      </button>
    </div>
  );
};