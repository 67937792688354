export const navItems = [
     {
    id: 1,
    url: "#",
    item: "Solutions",
    subItems: [ 
      { id: 11, url: "https://www.fezdelivery.co/individual", label: "Individuals" },
      { id: 12, url: "https://www.fezdelivery.co/corporates", label: "Corporates" },
      { id: 13, url: "https://www.fezdelivery.co/sme", label: "SME" },
    ],
  },
  
    {
      id: 2,
      url: "/track-delivery",
      item: "Track delivery",
    },
    {
      id: 3,
      url: "https://www.fezdelivery.co/uk",
      item: "International delivery",
    },
    {
      id: 4,
      url: "https://www.fezdelivery.co/about-us",
      item: "Company",
    },
    {
      id:5,
      url: "https://www.fezdelivery.co/blog",
      item: "Blog",
     
    },
    {
      id:6,
      url: "/delivery-estimate",
      item: "Get a quote",
    },
    {
      id: 7,
      url: "#",
      item: "Ship now",
      subItems: [ 
        { id: 71, url: "/ship-now?type=local", label: "Local Deliveries" },
        { id: 72, url: "/ship-now", label: "Send from Nigeria" },
        { id: 72, url: "/import", label: "Send to Nigeria" },
      ],
    },
  ];

