import { configureStore } from '@reduxjs/toolkit'
import { auth } from './slices/authSlice'
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { zonesApi } from './slices/getZonesSlice';
import { costApi } from './slices/costSlice';
import { multipleApi } from './slices/multiple';
import { trackApi } from './slices/track';
import { referalApi } from './slices/referralSlice';
import { termsApi } from './slices/terms';
import { importApi } from './slices/import';
import { lockerApi } from './slices/lockers';


export const store = configureStore({
  reducer: {
    [auth.reducerPath]: auth.reducer,
    [zonesApi.reducerPath]: zonesApi.reducer,
    [costApi.reducerPath]: costApi.reducer,
    [multipleApi.reducerPath]: multipleApi.reducer,
    [trackApi.reducerPath]: trackApi.reducer,
    [referalApi.reducerPath]: referalApi.reducer,
    [termsApi.reducerPath]: termsApi.reducer,
    [importApi.reducerPath]: importApi.reducer,
    [lockerApi.reducerPath]: lockerApi.reducer,
  },
  
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([auth.middleware,referalApi.middleware, trackApi.middleware, multipleApi.middleware, costApi.middleware, zonesApi.middleware, termsApi.middleware, importApi.middleware, lockerApi.middleware]),
})

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
