import { Button } from "./Button";
import { Input } from "./form/Input";

interface Props {
  onChange?: any;
  onTrackDetails?: any;
  value: string;
  label?: string;
  isLoading?: boolean;
  track?: boolean;
}


export const TrackCard = ({ onTrackDetails,label="Enter your tracking number", track, isLoading, value, onChange }: Props) => {
  return (
    <div className=" w-full ">
      <Input
        onChange={onChange}
        type="text"
        value={value}
        placeholder="Enter number"
        label={label}
        labelFor="Enter number"
        className="!bg-white !w-full !border !rounded-[4px]"
      />
      {!track && (
        <div className="mt-10 w-full ">
          <Button
            title="Track shipment"
            className="!py-3"
            onClick={onTrackDetails}
            loading={isLoading}
          />
        </div>
      )}
    </div>
  );
};