import { useRef, useState } from "react";
import { Button } from "./Button";
import moment from "moment";
interface Props {
  contentTitle?: string;
  content?: string;
  btnContent?: string;
  imgStr?: string;
  amount?: string;
  trackNo?: string;
  terms?: string;
  id?: string;
  currency?: string;
  errorMessage?: string;
  onClick?: any;
  deliveryRouteOnclick?: any;
  costDataResult?: any;
  deliveryEstimate?: boolean;
  onShowTerms?: any;
  deliveryRoute?: string;
}

interface TrackProps {
  data?: any;
  onClick?: any;
  showTrackModal?: boolean;
}

export const DashboardModalAction = ({
  contentTitle,
  content,
  btnContent,
  imgStr,
  amount,
  onClick,
  trackNo,
  currency,
  deliveryEstimate,
  deliveryRoute,
  deliveryRouteOnclick,
}: Props) => {
  const [showClipboard, setShowClipboard] = useState(false);
  const textRef = useRef(null);

  const handleCopy = () => {
    if (textRef.current) {
      const range = document.createRange();
      range.selectNode(textRef.current);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
        setShowClipboard(true);
      }
    }
  };

  return (
    <div className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
      <div className="relative p-4 w-full flex items-center  max-w-[650px] h-[100vh] md:h-auto mx-auto">
        <div className="relative bg-[white] py-1 min-w-[450px]  flex justify-center items-center mx-auto  rounded-[20px]   drop-shadow-md shadow-modalShadow">
          <div className="py-10 flex justify-center  w-full  flex-col  items-center px-6 lg:px-8">
            <h3 className="text-xl mb-1 text-[24px] font-onestBold font-[600] text-g-75">
              {contentTitle}
            </h3>
            <img src={imgStr} alt="" />
            <p className="text-center text-[14px] mb-1 text-gm-40 font-onestMedium font-normal">
              {content}
            </p>
            {amount && (
              <h5 className="text-[34px] mb-2 font-onestBold text-textColor">
                {`${currency} ${amount}`}
              </h5>
            )}
            {trackNo && (
              <div className="flex items-center justify-center space-x-2">
                <h5
                  ref={textRef}
                  className="text-[24px] mb-2 font-onestBold text-textColor"
                >
                  {trackNo}
                </h5>
                <div className="w-full">
                  <div onClick={handleCopy} className="cursor-pointer">
                    <img
                      src="/images/copy.png"
                      alt=""
                      className="h-5 w-5 -mt-2"
                    />
                  </div>
                  {showClipboard && (
                    <div className="bg-black  whitespace-nowrap top-0 right-0 left-0 bottom-0  absolute h-fit w-fit px-3 py-2 rounded-[4px] text-white text-[12px]">
                      <p> copied to clipboard</p>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="w-full  mt-4  space-y-3">
              {btnContent && (
                <Button title="Close" className={`!py-3 `} onClick={onClick} />
              )}
              {deliveryEstimate && (
                <Button title="Close" className="!py-3" onClick={onClick} />
              )}
              {deliveryRoute && (
                <Button
                  title={deliveryRoute}
                  className="!py-3 !bg-transparent border"
                  onClick={deliveryRouteOnclick}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShowOrderErrorandD = ({ onClick }: Props) => {
  return (
    <div className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
      <div className="relative p-4 w-full justify-center  flex items-center  max-w-[650px] h-[100vh] md:h-auto mx-auto">
        <div className="relative bg-white py-1 max-w-[450px]  rounded-[20px]   drop-shadow-md shadow-modalShadow">
          <div className="py-10 flex justify-center  flex-col  items-center px-6 lg:px-8">
            <div className="max-w-[350px] border  rounded-[8px]  p-3">
              <div className="flex space-x-1 items-center">
                <img src="/images/info.svg" className="h-3 w-3" alt="" />
                <p className="text-textColor text-[14px] font-onestBold">
                  Please note
                </p>
              </div>
              <p className="text-[14px] font-onest text-textColor">
                Longer processing and shipping times than usual. Delivery of
                your order may be delayed by 24-48 hours. We apologize for any
                inconvenience and appreciate your patience.
              </p>
            </div>
            <div className="w-full   space-y-2 mt-5">
              <Button title="Proceed" className="!py-3" onClick={onClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShowOrderLockYF = ({ onClick }: Props) => {
  return (
    <div className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
      <div className="relative  justify-center p-4 w-full flex items-center  max-w-[650px] h-[100vh] md:h-auto mx-auto">
        <div className="relative bg-white py-1 max-w-[450px]  rounded-[20px]   drop-shadow-md shadow-modalShadow">
          <div
            className=" flex justify-end px-5 mt-5 cursor-pointer"
            onClick={onClick}
          >
            <img src="/images/close.svg" alt="close" />
          </div>
          <div className="pb-10 pt-5 flex justify-center  flex-col  items-center px-6 lg:px-8">
            <div className="max-w-[350px] border  rounded-[8px] p-3">
              <div className="flex space-x-1 items-center">
                <img src="/images/info.svg" className="h-3 w-3" alt="" />
                <p className="text-textColor text-[14px] font-onestBold">
                  Please note
                </p>
              </div>
              <p className="text-[14px] font-onest text-textColor">
                Dear Customer, we have reached our order limit for the day for
                our <b>delivery option selected either bike or bus</b> option.
                Please select another delivery type or try again some other
                time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShowErrorandYS = ({ onClick }: Props) => {
  return (
    <div className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
      <div className="relative p-4 w-full justify-center  flex items-center  max-w-[650px] h-[100vh] md:h-auto mx-auto">
        <div className="relative bg-white py-1 max-w-[450px] rounded-[20px]   drop-shadow-md shadow-modalShadow">
          <div className="py-10 flex justify-center  flex-col  items-center px-6 lg:px-8">
            <div className="max-w-[350px] border  rounded-[8px]  p-3">
              <p className="text-[14px] font-onest text-textColor">
                Longer processing and shipping times than usual. Delivery of
                your order may be delayed by 24-48 hours. We apologize for any
                inconvenience and appreciate your patience.
              </p>
            </div>
            <div className="w-full   space-y-2 mt-5">
              <Button title="Proceed" className="!py-3" onClick={onClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TrackModal = ({ data, onClick }: TrackProps) => {
  if (!data) {
    return null;
  }

  return (
    <div
      onClick={onClick}
      className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]"
    >
      <div className="relative p-4 w-full flex  max-w-[800px] max-h-[700px] md:h-auto mx-auto">
        <div className="relative bg-[white] mx-auto w-full overflow-y-scroll px-6 py-3 !h-full max-w-[450px] my-auto   rounded-[12px]   drop-shadow-md shadow-modalShadow">
          <div
            onClick={onClick}
            className="flex justify-end mt-8 cursor-pointer"
          >
            <img src="/images/close.svg" alt="close" />
          </div>

          <>
            <p className="text-[20px] text-textColor font-onestBold">
              Tracking timeline
            </p>
            <div className="mt-5">
              <div className=" w-full space-y-5 my-5">
                <div className=" ">
                  <div className="w-full">
                    {data?.map((d: any) => (
                      <div className="flex space-x-3">
                        <div className="flex flex-col items-center">
                          <div className="bg-[#42CF39] rounded-full w-[22px] h-[22px] flex-shrink-0"></div>
                          <div className="bg-[#42CF39] w-[7px] flex items-center h-full flex-shrink-0"></div>
                        </div>
                        <div className="">
                          <p className="text-black text-[16px] font-onestBold">
                            {d?.orderStatus}
                          </p>
                          <p className="text-[13px] font-onestRegular text-textColor">
                            {d?.statusDescription}
                          </p>
                          <p className="text-[12px] font-onestRegular text-[#787E83]">
                            {moment(d?.statusCreationDate).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                    <div className="flex">
                      <div className=" bg-white border border-[#D4EFD0] rounded-full w-[22px] h-[22px] flex items-center flex-shrink-0"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export const ShowTermsModal = ({ id, onClick, terms }: Props) => {
  return (
    <div
      onClick={onClick}
      className="overflow-y-auto cursor-pointer overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] h-screen   bg-modal-bg right-0 left-0 z-[1001] "
    >
      <div className="relative md:pt-32 pt-52 max-h-[500px] justify-center p-4 w-full  h-screen flex items-center  max-w-[900px]  mx-auto">
        <div className="relative  bg-[white] py-1 max-w-[1000px]  rounded-[20px]   drop-shadow-md shadow-modalShadow">
          <div
            className=" flex justify-end px-5 mt-5 cursor-pointer"
            onClick={onClick}
          >
            <img src="/images/close.svg" alt="close" />
          </div>
          <div className="pb-10 pt-5 flex justify-center  flex-col  items-center px-6 lg:px-8">
            <div className="w-full border border-lightGreen rounded-[8px] bg-[#E8FFE5] p-3">
              <div className="flex space-x-1 items-center">
                <p className="text-textColor mb-2 text-[14px] font-onestBold">
                  Terms and Condition
                </p>
              </div>
              <div
                id={id}
                className="text-[14px] max-h-[400px] overflow-y-scroll font-onest text-textColor"
              >
                {terms}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ErrorModal = ({ onClick, errorMessage }: Props) => {
  return (
    <div className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
      <div className="relative  justify-center p-4 w-full flex items-center  max-w-[650px] h-[100vh] md:h-auto mx-auto">
        <div className="relative bg-white py-1 max-w-[450px]  rounded-[20px]   drop-shadow-md shadow-modalShadow">
          <div
            className=" flex justify-end px-5 mt-5 cursor-pointer"
            onClick={onClick}
          >
            <img src="/images/close.svg" alt="close" />
          </div>
          <div className="pb-10 pt-5 flex justify-center  flex-col  items-center px-4">
            <div className="max-w-[350px] border   rounded-[8px]  p-3">
              <p className="text-[18px] text-center font-onestMedium text-textColor">
                {errorMessage}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LasrraInfoModal = ({ onClick, onShowTerms }: Props) => {
  return (
    <div className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
      <div className="relative  justify-center p-4 w-full flex items-center  max-w-[650px] h-[100vh] md:h-auto mx-auto">
        <div className="relative bg-[#EFF6EE]  py-1 max-w-[450px]  rounded-[20px]   drop-shadow-md shadow-modalShadow">
          <div className="pb-10 pt-5 flex justify-center  flex-col  items-center px-4">
            <div className="max-w-[380px] rounded-[4px] ">
              <div className="flex space-x-1 items-center mb-1">
                <img src="/images/info.svg" className="h-3 w-3" alt="" />
                <p className="text-textColor text-[14px] font-onestBold">
                  Please note
                </p>
              </div>
              <p className="text-[14px] mt-3 font-onest text-textColor mb-1">
                Before scheduling your LASRRA card delivery, ensure your card is
                ready by dialing{" "}
                <span className="font-onestBold"> *347*641#.</span> using the
                phone number registered with LASRRA.
              </p>
              <p className="text-[14px] mt-2 font-onest text-textColor">
                By clicking on the 'Ship Now' button, you are acknowledging
                acceptance of our
                <span
                  className="underline ml-1 font-onestBold  cursor-pointer"
                  onClick={onShowTerms}
                >
                  Terms and Conditions
                </span>
              </p>
            </div>
          </div>
          <div className="flex justify-center w-full mb-5">
            <Button
              title="Ok"
              className="!py-3 w-fit !px-20"
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ExportInfoModal = ({ onClick }: Props) => {
  return (
    <div className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
      <div className="relative  justify-center p-4 w-full flex items-center  max-w-[650px] h-[100vh] md:h-auto mx-auto">
        <div className="relative bg-[#EFF6EE]  py-1 max-w-[450px]  rounded-[20px]   drop-shadow-md shadow-modalShadow">
          <div className="pb-6 pt-5 flex justify-center  flex-col  items-center px-4">
            <div className="max-w-[380px] rounded-[4px] ">
              <div className="flex space-x-1 items-center mb-1">
                <img src="/images/info.svg" className="h-3 w-3" alt="" />
                <p className="text-textColor text-[14px] font-onestBold">
                  Important Update On International Orders
                </p>
              </div>
              <p className="text-[14px] mt-3  font-onestRegular text-textColor mb-1">
                Due to fluctuating exchange rates, the pricing of international
                orders is subject to monthly changes.
              </p>

              <p className="text-[14px] mt-3  font-onestRegular text-textColor mb-1">
                You will be contacted if additional payment is required.
              </p>

              <p className="text-[14px] mt-3  font-onestRegular text-textColor mb-1">
                Thank you for your understanding.
              </p>
            </div>
          </div>
          <div className="flex justify-center w-full mb-5">
            <Button
              title="Continue"
              className="!py-3 w-fit !px-20"
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export const DelayInfoModal = ({ onClick }: Props) => {
  return (
    <div className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
      <div className="relative  justify-center p-4 w-full flex items-center  max-w-[650px] h-[100vh] md:h-auto mx-auto">
        <div className="relative bg-[#EFF6EE]  py-1 max-w-[450px]  rounded-[20px]   drop-shadow-md shadow-modalShadow">
          <div className="pb-6 pt-5 flex justify-center  flex-col  items-center px-4">
            <div className="max-w-[400px] rounded-[4px] ">
              <div className="flex space-x-1 items-center mb-1">
                <img src="/images/info.svg" className="h-3 w-3" alt="" />
                <p className="text-textColor text-[14px] font-onestBold">
                Important Notice
                </p>
              </div>
              <p className="text-[14px] mt-3  font-onestRegular text-textColor mb-1">
              We value your satisfaction and want to keep you informed. 
              </p>

              <p className="text-[14px] mt-3  font-onestRegular text-textColor mb-1">
              Please be aware that orders placed between 2 pm on Fridays and 9 pm on Sundays will be picked up on Monday. 
              </p>

              <p className="text-[14px] mt-3  font-onestRegular text-textColor mb-1">
              Thank you for your understanding and patience.
              </p>
            </div>
          </div>
          <div className="flex justify-center w-full mb-5">
            <Button
              title="Continue"
              className="!py-3 w-fit !px-20"
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
