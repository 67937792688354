import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface Props{
  clientAddress: string,
  recipientAddress: string,
  recipientName: string,
  recipientPhone: string,
  recipientEmail: string,
  quantity: number,
    weight: number,
  itemDescription: string,
  orderDate: any,
  cost: string,
  discount: string,
  discountedCost: string,
  paymentStatus: string,
  paymentReference: string,
  additionalNote: string,
  thirdparty:string,
  thirdparty_sendersName: string,
  thirdparty_sendersPhone: string,
  zoneID: number,
  ZoneName: string,
  locationID: number,
  locationName: string,
  dropzoneID: number,
  dropZoneName: string,
  droplocationID: number,
  droplocationName: string,
  transportMode: string,
  deliveryType: string,
  valueOfItem: number,
  expanded:boolean,
  clientName:string
  clientPhone:string,
  referralCode?:string,

}

interface IntlProps {
  requestType: string;
  data: [
    {
      requestType: string;
      clientAddress: string;
      clientName: string;
      clientPhone: string;
      recipientAddress: string;
      recipientName: string;
      recipientPhone: string;
      recipientEmail: string;
      clientEmail: string;
      trackingId: string;
      businessName: string;
      dropZoneName: string;
      droplocationID: number;
      droplocationName: string;
      itemCategory: number;
      itemName: string;
      importLocation: number;
      thirdparty: string;
      thirdparty_sendersName: string;
      thirdparty_sendersPhone: string;
      orderNo: string;
      dropzoneID: number;
      uploadedProofExtension: string;
      quantity: number;
      weight: number;
      itemDescription: string;
      orderDate: string;
      cost: number;
      valueOfItem: number;
      paymentStatus: string;
      paymentReference: string;
      uploadedProof: string;
      additionalNote:string;
      referralCode?:string,

    }
  ];
}
interface RefProps{
orderNo:string,
reference?:string,
referralCode?:string

}

export const multipleApi = createApi({
  reducerPath: "multipleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}v2`,
    credentials: "same-origin",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
     orderMultiple: builder.mutation<any, Props>({
      query: (body) => ({
        url: `orderMultiple`,
        method: 'POST',
        body :[body]
        
      }
      ),
  }),

       updateReference: builder.mutation<any, RefProps>({
      query: (body) => ({
        url: `updateReference`,
        method: 'POST',
        body :body
        
      }
      ),
  }),
  removeOrder: builder.mutation<any, any>({
    query: (body) => ({
      url: `/removeOrder`,
      method: 'POST',
      body :body
      
    }
    ),
}),
     createImportOrder: builder.mutation<any, IntlProps>({
      query: (body) => ({
        url: `orderMultiple`,
        method: 'POST',
        body :body
        
      }
      ),
  }),
  }),
});

export const {  useOrderMultipleMutation, useRemoveOrderMutation, useUpdateReferenceMutation, useCreateImportOrderMutation } = multipleApi;
