import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface Props{
  ori_zone: string,
  des_zone: string,
  deliveryType: string,
  weight?:number,
  referralCode:string
}

export const referalApi = createApi({
  reducerPath: "referalApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}v1`,
    credentials: "same-origin",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
     getReferalStatus: builder.mutation<any, Props>({
      query: (body) => ({
        url: `/getPricesWithReferral`,
        method: 'POST',
        body
        
      }
      ),
  }),
  }),
});

export const { useGetReferalStatusMutation } = referalApi;
