import React from "react";
import { Loader } from "./Loader";

interface Props {
  isLoading?: boolean;
  children?: any;
}
const PageFrame = ({ isLoading, children }:Props) => {
  if (isLoading) {
    return (
      <div
        className={`w-full h-screen  flex items-center justify-center`}
      >
        <Loader />
      </div>
    );
  }

  return children;
};

export default PageFrame;
