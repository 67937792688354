import { Link } from "react-router-dom";
import { navItems } from "./data";
import React, { useState } from "react";

interface SideBarProps {
  hasSet: any;
  open: any;
  navbar: boolean;
  setNavbar: any;
}

export const SideBar = ({ open, navbar, setNavbar, hasSet }: SideBarProps) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const setNavValue = () => {
    if (navbar) {
      hasSet(false);
      setNavbar(false);
    } else {
      hasSet(true);
      setNavbar(true);
    }
  };
  const handleItemClick = (itemName:any) => {
    if (selectedItem === itemName) {
      setSelectedItem(null);
    } else {
      setSelectedItem(itemName);
    }
  };
  return (
    <div className="block bg-[black] bmd:hidden h-full w-full">
      <div
        className={`fixed top-0  py-4 ${
          open ? "left-0" : "left-[-1000px]"
        }  overflow-x-hidden ease-in duration-500 flex h-full  flex-col  bg-[white] w-full min-h-screen z-[1000]`}
      >
        <div className="h-[64px] pb-2 bg-[white] border-b border-b-[#EBECF0] px-5 sm:px-10 xl:px-20 justify-between items-center flex ">
          <div className="cursor-pointer">
            <img
              src="/images/fez-logo.svg"
              className="w-[68px] h-10"
              alt="logo"
            />
          </div>
          {navbar && (
            <div className="curose-pointer" onClick={() => setNavValue()}>
              <img
                src="/images/close.svg"
                className="h-5 w-5 cursor-pointer"
                alt="close"
              />
            </div>
          )}
        </div>
        <ul className="flex flex-col mt-5 text-textColor font-onestMedium text-[14px]  px-5 space-y-5 ">
          {navItems?.map((i: any) => (
            <li className="cursor-pointer" key={i.id}>
              <Link to={i.url}>
                <div
                  className={`${
                    i.item.toLowerCase() === "ship now" && "bg-btnColor"
                  } w-full py-2.5 px-4 rounded-[4px] flex justify-between`}
                  onClick={() => handleItemClick(i.item)}
                >
                  <p
                    className={`text-textColor font-[700] whitespace-nowrap text-start`}
                  >
                    {i.item}
                  </p>
                  {i.subItems && (
                    <div>
                      {selectedItem === i.item ? (
                        <img src="/images/arrow-up.png" className="h-4 w-4" alt="arrow-up" />
                      ) : (
                        <img src="/images/down-arrow.png" className="h-4 w-4" alt="arrow-down" />
                      )}
                    </div>
                  )}
                </div>
              </Link>
              {i.subItems?.map((sub: any, idx:number) => (
                <React.Fragment key={idx}>
                  {selectedItem === i.item && (
                    <ul className="flex flex-col mt-5 text-textGreen font-onestMedium text-[14px]  px-5 space-y-3 ">
                      <li
                        onClick={() => {
                          window.location.href = sub.url;
                        }}
                      >
                        {sub.label}
                      </li>
                    </ul>
                  )}
                </React.Fragment>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
