import { Link } from "react-router-dom";
import { useState } from "react";
import { navItems } from "./data";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

interface NavProps {
  hasSet: any;
  navbar: boolean;
  setNavbar: any;
}

export const NavBar = ({ hasSet, navbar, setNavbar }: NavProps) => {
  const [hoveredSolutions, setHoveredSolutions] = useState<boolean>(false);
  const [hoveredUseCases, setHoveredUseCases] = useState<boolean>(false);
  const [shipCases, setShipCases] = useState<boolean>(false);

  const setNavValue = () => {
    if (navbar) {
      hasSet(false);
      setNavbar(false);
    } else {
      hasSet(true);
      setNavbar(true);
    }
  };
  const getArrowIcon = (id:number, shipCases:boolean, hoveredUseCases:boolean, hoveredSolutions:boolean) => {
    if (id === 7) {
      return shipCases ? (
        <IoIosArrowUp className="text-[18px]" />
      ) : (
        <IoIosArrowDown className="text-[18px]" />
      );
    } else if (id === 1) {
      return hoveredSolutions ? (
        <IoIosArrowUp className="text-[18px]" />
      ) : (
        <IoIosArrowDown className="text-[18px]" />
      );
    }
    else if (id === 2) {
      return hoveredUseCases ? (
        <IoIosArrowUp className="text-[18px]" />
      ) : (
        <IoIosArrowDown className="text-[18px]" />
      );
    }
  
    return null;
  };

  return (
    <div className="h-[64px] py-10 items-center space-x-5 bg-[#EFF6EE] w-full  z-[1000] justify-between px-5 sm:px-10 xl:px-20 flex">
      <div className="">
        <Link to={"https://www.fezdelivery.co"}>
          <img
            src="/images/fez-logo.svg"
            className="w-[104px] h-10"
            alt="logo"
          />
        </Link>
      </div>
      <div className="w-full justify-end bmd:flex hidden">
        <ul className="flex text-[#222] font-onestMedium text-[14px] space-x-10 items-center">
          {navItems?.map((item) => (
            <li
              className="relative"
              key={item.id}
              onMouseEnter={() =>
                item.id === 1
                  ? setHoveredSolutions(true)
                  : item.id === 2
                  ? setHoveredUseCases(true)
                  : item.id === 7
                  ? setShipCases(true)
                  : null
              }
              onMouseLeave={() =>
                item.id === 1
                  ? setHoveredSolutions(false)
                  : item.id === 2
                  ? setHoveredUseCases(false)
                  : item.id === 7
                  ? setShipCases(false)
                  : null
              }
            >
              <div
                className={`${
                  item.id === 7 &&
                  "bg-btnColor cursor-pointer text-center flex flex-col  w-[130px] py-2.5"
                } ${
                  item.url === "#" && "pr-3"
                } py-2.5 rounded-[4px]  cursor-pointer `}
              >
                <div
                  onClick={() => {
                    window.location.href = item.url;
                  }}
                >
                  <p className="text-textColor font-medium text-[16px] font-onestRegular whitespace-nowrap text-center">
                    {item.item}
                  </p>
                </div>
              </div>
              {item.subItems &&
                (item.id === 1
                  ? hoveredSolutions
                  : item.id === 2
                  ? hoveredUseCases
                  : item.id === 7
                  ? shipCases
                  : false) && (
                  <div
                    className={`absolute ${
                      item?.id === 7 ? "-ml-8" : "-mt-1"
                    } py-5 px-3 animate__animated animate-faster border-btnColor animate__fadeInUp top-full left-0 z-10 bg-white border shadow-md min-w-[150px] rounded-md`}
                    onMouseEnter={() =>
                      item.id === 1
                        ? setHoveredSolutions(true)
                        : item.id === 2
                        ? setHoveredUseCases(true)
                        : item.id === 7
                        ? setShipCases(true)
                        : null
                    }
                    onMouseLeave={() =>
                      item.id === 1
                        ? setHoveredSolutions(false)
                        : item.id === 2
                        ? setHoveredUseCases(false)
                        : item.id === 7
                        ? setShipCases(false)
                        : null
                    }
                  >
                    <ul className="py-2 px-4 space-y-4">
                      {item.subItems.map((subItem) => (
                        <li key={subItem.id}>
                          <div
                            onClick={() => {
                              window.location.href = subItem.url;
                            }}
                            className="text-black cursor-pointer  whitespace-nowrap  text-[16px] hover:text-btnColor"
                          >
                            {subItem.label}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              {item.subItems && (
                <div
                  className={`absolute top-[29px] ${
                    item.id === 7 ? "-right-0" : "-right-6"
                  } transform -translate-y-1/2 w-8 h-8 transition-transform duration-300 cursor-pointer`}
                >
                 {getArrowIcon(item.id, shipCases, hoveredUseCases, hoveredSolutions)}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="bmd:hidden font-onestMedium flex w-full justify-end ">
        {!navbar && (
          <div
            className="flex cursor-pointer items-center"
            onClick={() => setNavValue()}
          >
            <div>
              <img
                src="/images/dots.jpeg"
                className="h-6 w-12"
                alt="open-nav-icon"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
