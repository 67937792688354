/* eslint-disable */
import { useEffect, useState } from "react";
import { PageLayout } from "../components/Layout";
import { ToastContainer, toast } from "react-toastify";
import "animate.css";
import { object, string, number } from "yup";
import { InformationLayout } from "../components/InfoLayout";
import { Button } from "../components/Button";
import { SearchableDropdown } from "../components/form/Dropdown";
import { Input } from "../components/form/Input";
import { SectionBack } from "../components/SectionBack";
import { TextArea } from "../components/form/textArea";
import {
  DashboardModalAction,
  ErrorModal,
  ShowErrorandYS,
  ShowOrderErrorandD,
  ShowOrderLockYF,
  ShowTermsModal,
} from "../components/Modal";
import { useGetDropOffZonesQuery } from "../services/slices/getZonesSlice";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  useGetImportLocationsQuery,
  useGetItemsCategoryQuery,
} from "../services/slices/import";
import {
  useGetImportCostPriceMutation,
  useGetImportReferralPriceMutation,
} from "../services/slices/costSlice";
import {
  useCreateImportOrderMutation,
  useUpdateReferenceMutation,
} from "../services/slices/multiple";
import moment from "moment";
import { scrollToTop, validateEmail } from "../helper";
import SelectInput from "../components/form/SelectInput";
import { devTerms } from "../components/termsAndCondition";

interface IntlProps {
  requestType: string;
  data: [
    {
      requestType: string;
      clientAddress: string;
      clientName: string;
      clientPhone: string;
      recipientAddress: string;
      recipientName: string;
      recipientPhone: string;
      recipientEmail: string;
      clientEmail: string;
      trackingId: string;
      businessName: string;
      dropZoneName: string;
      droplocationID: number;
      droplocationName: string;
      itemCategory: number;
      itemName: string;
      importLocation: number;
      thirdparty: string;
      thirdparty_sendersName: string;
      thirdparty_sendersPhone: string;
      orderNo: string;
      dropzoneID: number;
      uploadedProofExtension: string;
      quantity: number;
      weight: number;
      itemDescription: string;
      orderDate: string;
      cost: number;
      discount: string;
      discountedCost: number;
      valueOfItem: number;
      paymentStatus: string;
      paymentReference: string;
      uploadedProof: string;
      additionalNote: string;
      referralCode: string;
      source: string;
    }
  ];
}
interface RefProps {
  orderNo: string;
  reference?: string;
  referralCode?: string;
}
const Import = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [queryTwo, setQueryTwo] = useState("");
  const [queryThree, setQueryThree] = useState("");
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const [isOpenThree, setIsOpenThree] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [dropOffZoneData, setDropOffZoneData] = useState([]);
  const [showAddresses, setShowAddresses] = useState<any>([]);
  const [locationData, setLocationData] = useState<any>([]);
  const [itemCategory, setItemCategory] = useState<any>([]);
  const { data: dropOffData } = useGetDropOffZonesQuery();
  const [orderStatus, setOrderStatus] = useState(true);
  const [showOrderError, setShowOrderError] = useState(false);
  const [showOrderErrorOne, setShowOrderErrorOne] = useState(false);
  const [showOrderErrorThree, setShowOrderErrorThree] = useState(false);
  const [zoneOptionThree, setZoneOptionThree] = useState<any>(null);
  const [itemOption, setItemOption] = useState<any>(null);
  const [errorCost, setErrorCost] = useState("");
  const [costError, setCostError] = useState("no");
  const [clientUserId, setClientUserId] = useState("");
  const [copiedAdd, setCopiedAdd] = useState("");
  const [clientUserPhone, setClientUserPhone] = useState("");
  const [clientUserAddress, setClientUserAddress] = useState("");
  const [showClipboard, setShowClipboard] = useState(false);
  const [applyReferral, setApplyReferral] = useState(false);
  const [currencyCode, setCurrencyCode] = useState("");
  const [updateReference] = useUpdateReferenceMutation();

  const [
    orderMultiple,
    {
      data: multipleData,
      isLoading: isMultipleLoading,
      isError: multipleError,
    },
  ] = useCreateImportOrderMutation();
  const [query, setQuery] = useState("");
  const [dispatchFirstData, setDispatchFirstData] = useState({
    pickUpAddress: "",
    pickUpZone: "",
    orderNumber: "",
    trackingId: "",
    dropOffLocationId: "",
    dropOffZone: "",
    dropOffLocationName: "",
    importAddress: "",
    importLocationName: "",
    localGovernmentArea: "",
    country: "",
    senderName: "",
    vendorName: "",
    dropOffAddress: "",
    itemName: "",
    senderPhone: "",
    senderAltPhone: "",
    senderEmail: "",
    recipientEmail: "",
    recipientName: "",
    recipientPhone: "",
    itemDescription: "",
    file: "",
    fileName: "",
    fileNames: "",
    quantity: "",
    referenceId: "",
    weight: "",
    item: "",
    cost: "",
    additionalNote: "",
    referralCode: "",
  });
  const [infoErrorMessages, setInfoErrorMessages] = useState({
    pickUpAddress: "",
    pickUpZone: "",
    orderNumber: "",
    trackingId: "",
    dropOffZone: "",
    importAddress: "",
    pickUpDate: "",
    dropOffAddress: "",
    senderName: "",
    vendorName: "",
    localGovernmentArea: "",
    itemName: "",
    senderPhone: "",
    senderAltPhone: "",
    senderEmail: "",
    recipientName: "",
    recipientEmail: "",
    recipientPhone: "",
    itemDescription: "",
    quantity: "",
    referenceId: "",
    weight: "",
    file: "",
    cost: "",
    country: "",
    item: "",
    terms: "",
    additionalNote: "",
  });
  const [
    getImportCostPrice,
    { data: costData, isLoading: isCostLoading, isError: isCostError },
  ] = useGetImportCostPriceMutation();
  const [
    getImportReferralPrice,
    {
      data: referralData,
      isLoading: isReferralLoading,
      isError: isReferralError,
    },
  ] = useGetImportReferralPriceMutation();

  const { data: importLocations } = useGetImportLocationsQuery();
  const { data: itemCategories } = useGetItemsCategoryQuery();
  const filteredDropOffOptions = dropOffZoneData.reduce(
    (acc: any, zone: any) => {
      const filteredLocations = zone.location.filter((location: any) =>
        location.location.toLowerCase().includes(queryThree?.toLowerCase())
      );

      if (filteredLocations.length > 0) {
        acc.push({
          zone: zone.name,
          locations: filteredLocations,
        });
      }

      return acc;
    },
    []
  );
  const userDetails: any = localStorage.getItem("fez-user");

  useEffect(() => {
    if (userDetails) {
      const { clientID, clientsPhone, clientsAddress } =
        JSON.parse(userDetails);
      setClientUserId(clientID);
      setClientUserPhone(clientsPhone);
      setClientUserAddress(clientsAddress);
    }
  }, [userDetails]);
  let userSchema = object({
    country: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            country: "Country  is required",
          }));
          throw new TypeError("Country  is required");
        }
        return value;
      })
      .required("Country  is required"),
    item: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            item: "Item  is required",
          }));
          throw new TypeError("Item  is required");
        }
        return value;
      })
      .required("Item  is required"),

    dropOffZone: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            dropOffZone: "Dropoff  zone  is required",
          }));
          throw new TypeError("Dropoff  zone  is required");
        }
        return value;
      })
      .required("Dropoff  zone  is required"),
    dropOffAddress: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            dropOffAddress: "Drop off address is required",
          }));
          throw new TypeError("Drop off address is required");
        }
        return value;
      })
      .required("Pick up address is required"),

    orderNumber: string().transform((value, originalValue) => {
      if (originalValue === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          orderNumber: "Order Number is required",
        }));
      } else {
        setInfoErrorMessages((prev) => ({
          ...prev,
          orderNumber: "",
        }));
      }
      return value;
    }),

    vendorName: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            vendorName: "Vendor/ Business Name is required",
          }));
        }
        return value;
      })
      .required("Vendor/ Business Name is required"),

    itemName: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            itemName: "Item Name is required",
          }));
        }
        return value;
      })
      .required("Item Name is required"),

    weight: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            weight: "weight must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            weight: "weight is required",
          }));

          throw new TypeError("weight must be a valid number");
        }

        return value;
      })
      .required("weight must be a number")
      .positive()
      .integer(""),
  });

  let userSchemaTwo = object({
    senderName: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderName: "Sender name is required",
          }));
          throw new TypeError("Sender name is required");
        }
        return value;
      })
      .required("Sender name is required"),
    recipientName: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            recipientName: "Recipient name is required",
          }));
          throw new TypeError("Recipient name is required");
        }
        return value;
      })
      .required("Recipient name is required"),
    itemDescription: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            itemDescription: "Item description is required",
          }));
          throw new TypeError("Item description is required");
        }
        return value;
      })
      .required("Item description is required"),

    senderPhone: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderPhone: "phone number must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderPhone: "phone number is required",
          }));
          throw new TypeError("phone number must be a valid number");
        }
        return value;
      })
      .required("phone number must be a number")
      .positive()
      .integer(""),
    senderAltPhone: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderAltPhone: "phone number must be a valid number",
          }));
        }
        if (!isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderAltPhone: "",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderAltPhone: "",
          }));
        }
        return value;
      })
      .positive()
      .integer(""),
    recipientPhone: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            recipientPhone: "phone number must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            recipientPhone: "phone number is required",
          }));
          throw new TypeError("phone number must be a valid number");
        }
        return value;
      })
      .required("phone number must be a number")
      .positive()
      .integer(""),
    quantity: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            quantity: "quantity must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            quantity: "quantity is required",
          }));
          throw new TypeError("quantity must be a valid number");
        }
        return value;
      })
      .required("quantity must be a number")
      .positive()
      .integer(""),
    cost: number()
      .transform((value, originalValue) => {
        if (isNaN(originalValue)) {
          setInfoErrorMessages((prev) => ({
            ...prev,
            cost: "cost must be a valid number",
          }));
        }
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            cost: "cost is required",
          }));
          throw new TypeError("cost must be a valid number");
        }
        return value;
      })
      .required("cost must be a number")
      .positive()
      .integer(""),
    senderEmail: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            senderEmail: "Email is required",
          }));
          throw new TypeError("Email is required");
        }
        return value;
      })
      .email("Invalid email format")
      .required("Email is required"),
    recipientEmail: string()
      .transform((value, originalValue) => {
        if (originalValue === "") {
          setInfoErrorMessages((prev) => ({
            ...prev,
            recipientEmail: "Email is required",
          }));
          throw new TypeError("Email is required");
        }
        return value;
      })
      .email("Invalid email format")
      .required("Email is required"),
  });

  const handleChange = async (evt: any) => {
    const { name, value } = evt.target;

    setDispatchFirstData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "orderNumber" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, orderNumber: "" }));
    }
    if (name === "trackingId" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, orderNumber: "" }));
    }
    if (name === "orderNumber" && value === "") {
      if (name === "trackingId" && value === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          orderNumber: "Order number is required",
        }));
      }
    }
    if (name === "weight" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, weight: "" }));
    }
    if (name === "itemName" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, itemName: "" }));
    }
    if (name === "vendorName" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, vendorName: "" }));
    }

    if (name === "dropOffAddress" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, dropOffAddress: "" }));
    }

    try {
      await userSchema.validateAt(name, { [name]: value });
    } catch (error: any) {
      if (error) {
        // setInfoErrorMessage(error.message);
      }
    }
  };

  const handleChangeTwo = async (evt: any) => {
    const { name, value } = evt.target;

    setDispatchFirstData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "senderPhone" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, senderPhone: "" }));
    }
    if (name === "senderAltPhone" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, senderPhone: "" }));
    }
    if (name === "senderName" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, senderName: "" }));
    }
    if (name === "senderEmail" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, senderEmail: "" }));
    }
    if (name === "recipientName" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, recipientName: "" }));
    }
    if (name === "recipientPhone" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, recipientPhone: "" }));
    }
    if (name === "quantity" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, quantity: "" }));
    }
    if (name === "cost" && value !== "" && !isNaN(value)) {
      setInfoErrorMessages((prev) => ({ ...prev, cost: "" }));
    }
    if (name === "itemDescription" && value !== "") {
      setInfoErrorMessages((prev) => ({ ...prev, itemDescription: "" }));
    }

    try {
      await userSchemaTwo.validateAt(name, { [name]: value });
    } catch (error: any) {
      if (error) {
      }
    }
  };

  useEffect(() => {
    if (
      Number(dispatchFirstData.cost) >=
        Number(dispatchFirstData?.importAddress) &&
      dispatchFirstData.file === ""
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        file: "Invoice is required",
      }));
    } else {
      setInfoErrorMessages((prev) => ({
        ...prev,
        file: "",
      }));
    }
  }, [
    dispatchFirstData?.cost,
    dispatchFirstData?.importAddress,
    dispatchFirstData.file,
  ]);

  const checkValidate = async () => {
    try {
      await userSchema.validate(dispatchFirstData);
    } catch (err: any) {
      const errorMessage = err?.errors?.join(", ");
    }
  };

  const checkValidateTwo = async () => {
    try {
      await userSchemaTwo.validate(dispatchFirstData);
    } catch (err: any) {
      const errorMessage = err?.errors?.join(", ");
    }
  };
  
  const handleCountryOption = (option: any) => {
    if (option === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "Country is required",
      }));
    }
    if (option !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        country: "",
      }));
    }
    const selectedCountry = locationData.find(
      (location: any) => location?.country?.name === option
    );
    if (selectedCountry) {
      const { country, currency } = selectedCountry;
      setDispatchFirstData((prev) => ({
        ...prev,
        importLocationName: country?.name,
      }));
      setDispatchFirstData((prev) => ({
        ...prev,
        country: selectedCountry?.id,
      }));
      setDispatchFirstData((prev) => ({
        ...prev,
        importAddress: selectedCountry.proof,
      }));
      setQueryTwo(country?.name);
      setIsOpenTwo(false);
      setShowAddresses(selectedCountry);
      setCurrencyCode(currency?.code);
    } else {
      console.error(`Country '${option}' not found in locationData`);
    }
  };

  const handleItemOption = (option: any) => {
    if (option === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        item: "Item Category is required",
      }));
    }
    if (option !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        item: "",
      }));
    }
    const selectedItem = itemCategory.find(
      (item: any) => item?.name === option
    );
    setQuery(selectedItem?.name);
    setIsOpen(false);
    setDispatchFirstData((prev) => ({
      ...prev,
      item: selectedItem?.id,
    }));
  };

  useEffect(() => {
    if (dropOffData) {
      setDropOffZoneData(dropOffData.zones);
    }
  }, [dropOffData]);

  const getCostPriceData = async () => {
    setErrorCost("");
    setCostError("no");
    setOrderStatus(true);
    try {
      const requestPayload = {
        importLocationID: Number(dispatchFirstData?.country),
        destinationZoneID: Number(dispatchFirstData?.dropOffZone),
        weight: Number(dispatchFirstData.weight),
      };

      await getImportCostPrice(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  };

  useEffect(() => {
    if (applyReferral) {
      getCostReferralData();
    }
  }, [
    applyReferral,
    dispatchFirstData.weight,
    dispatchFirstData?.dropOffZone,
    dispatchFirstData?.country,
  ]);

  const getCostReferralData = async () => {
    setErrorCost("");
    setCostError("no");
    setOrderStatus(true);
    try {
      const requestPayload = {
        importLocationID: Number(dispatchFirstData?.country),
        destinationZoneID: Number(dispatchFirstData?.dropOffZone),
        weight: Number(dispatchFirstData.weight),
        referralCode: dispatchFirstData?.referralCode,
      };

      await getImportReferralPrice(requestPayload).unwrap();
    } catch (error) {
      setCostError("Network Error! Please, try again!");
    }
  };
  useEffect(() => {
    if (isCostError) {
      setErrorCost("Network Error! Please, try again!");
    }
  }, [isCostError]);

  useEffect(() => {
    if (showError) {
      setErrorCost(
        multipleData?.status.toLowerCase() === "error"
          ? multipleData?.description
          : ""
      );
    }
  }, [showError, multipleData?.status]);

  useEffect(() => {
    if (errorCost === "The data.0.orderNo has already been taken.") {
      setErrorCost("The Order Number has already been taken");
      setStep(1);
    }
  }, [errorCost]);
  useEffect(() => {
    if (
      dispatchFirstData.dropOffZone !== "" &&
      dispatchFirstData.country !== "" &&
      dispatchFirstData.weight !== ""
    ) {
      getCostPriceData();
    }
  }, [
    dispatchFirstData.dropOffZone,
    dispatchFirstData.country,
    dispatchFirstData.weight,
  ]);

  useEffect(() => {
    if (importLocations) {
      if (importLocations?.data?.data) {
        setLocationData(importLocations?.data?.data);
      }
    }
  }, [importLocations]);

  useEffect(() => {
    if (itemCategories) {
      if (itemCategories?.data) {
        setItemCategory(itemCategories?.data);
      }
    }
  }, [itemCategories]);

  const focusHandleDropOff = () => {
    if (queryThree === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (queryThree !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }
  };

  const focusHandleItemCategory = () => {
    if (query === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        item: "Item category is required",
      }));
    }
    if (query !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        item: "",
      }));
    }
  };

  const handleFileChange = (event: any) => {
    if (event.target === null) {
      console.error("No file selected");
      return;
    }

    const file = event.target.files[0];
    if (!file) {
      console.error("Failed to retrieve file");
      return;
    }
    if (file) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        file: "",
      }));
      setDispatchFirstData((prev) => ({
        ...prev,
        fileName: file?.type.split("/")[1],
      }));
      setDispatchFirstData((prev) => ({
        ...prev,
        fileNames: file?.name,
      }));
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event: any) => {
      const base64EncodedData = event.target.result;
      const base64Data = base64EncodedData.substring(
        base64EncodedData.indexOf(",") + 1
      );
      setDispatchFirstData((prev) => ({
        ...prev,
        file: base64Data,
      }));
    };
  };

  const handleOptionClickTwo = (option: any) => {
    if (option.location === "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "Drop off zone is required",
      }));
    }
    if (option.location !== "") {
      setInfoErrorMessages((prev) => ({
        ...prev,
        dropOffZone: "",
      }));
    }
    setQueryThree(option.location);
    setIsOpenThree(false);
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationName: option.location,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffZone: option.zoneid,
    }));
    setDispatchFirstData((prev) => ({
      ...prev,
      dropOffLocationId: option.id,
    }));
  };

  const getMultipleData = async () => {
    const requestPayload: IntlProps = {
      requestType: "import",
      data: [
        {
          requestType: "import",
          clientAddress: clientUserAddress,
          clientName: clientUserId,
          clientPhone: clientUserPhone,
          recipientName: dispatchFirstData?.recipientName,
          recipientPhone: dispatchFirstData?.recipientPhone,
          recipientEmail: dispatchFirstData?.recipientEmail,
          recipientAddress: dispatchFirstData?.dropOffAddress,
          clientEmail: dispatchFirstData?.senderEmail,
          thirdparty: "true",
          thirdparty_sendersName: dispatchFirstData.senderName,
          thirdparty_sendersPhone: dispatchFirstData.senderPhone,
          trackingId: dispatchFirstData?.trackingId,
          businessName: dispatchFirstData?.vendorName,
          itemCategory: Number(dispatchFirstData?.item),
          itemName: dispatchFirstData?.itemName,
          importLocation: Number(dispatchFirstData?.country),
          orderNo: dispatchFirstData?.orderNumber,
          dropzoneID: Number(dispatchFirstData?.dropOffZone),
          dropZoneName: zoneOptionThree,
          droplocationID: Number(dispatchFirstData.dropOffLocationId),
          droplocationName: dispatchFirstData.dropOffLocationName,
          uploadedProofExtension: dispatchFirstData?.fileName?.replace(
            "image/",
            ""
          ),
          quantity: Number(dispatchFirstData?.quantity),
          weight: Number(dispatchFirstData?.weight),
          itemDescription: dispatchFirstData?.itemDescription,
          orderDate: moment(new Date()).format("YYYY-MM-DD"),
          cost: Number(costData?.rate?.replace(/,/g, "")),
          paymentStatus: "ppc",
          paymentReference: "",
          discountedCost: referralData?.rate
            ? Number(referralData?.rate?.replace(/,/g, ""))
            : Number(costData?.rate?.replace(/,/g, "")),
          discount: costData?.discount,
          valueOfItem: Number(dispatchFirstData.cost),
          additionalNote: dispatchFirstData.additionalNote,
          uploadedProof: dispatchFirstData?.file,
          referralCode: dispatchFirstData?.referralCode,
          source: "Fez Website",
        },
      ],
    };
    await orderMultiple(requestPayload)
      .unwrap()
      .then((data) => {
        console.log("data", data);
      })
      .catch((error: any) => {
        if (error?.data?.description) {
          toast.error(error?.data?.description, {
            className: "toast-error",
            progressClassName: "Toastify__progress-bar--error",
          });
        } else {
          toast.error("Network Error", {
            className: "toast-error",
            progressClassName: "Toastify__progress-bar--error",
          });
        }
        setCostError("Network Error! Please, try again!");
      });
  };

  useEffect(() => {
    if (
      dispatchFirstData?.senderEmail !== "" &&
      validateEmail(dispatchFirstData?.senderEmail) === null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        senderEmail: "Invalid email format",
      }));
    } else if (
      dispatchFirstData?.senderEmail !== "" &&
      validateEmail(dispatchFirstData?.senderEmail) !== null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        senderEmail: "",
      }));
    }
  }, [dispatchFirstData?.senderEmail]);

  useEffect(() => {
    if (dispatchFirstData.cost > dispatchFirstData.importAddress) {
      if (dispatchFirstData.file === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          file: "invoice is required",
        }));
      }
    } else {
      setInfoErrorMessages((prev) => ({
        ...prev,
        file: "",
      }));
    }
  }, [
    dispatchFirstData.importAddress,
    dispatchFirstData.file,
    dispatchFirstData.cost,
  ]);

  useEffect(() => {
    if (
      dispatchFirstData?.recipientEmail !== "" &&
      validateEmail(dispatchFirstData?.recipientEmail) === null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        recipientEmail: "Invalid email format",
      }));
    } else if (
      dispatchFirstData?.recipientEmail !== "" &&
      validateEmail(dispatchFirstData?.recipientEmail) !== null
    ) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        recipientEmail: "",
      }));
    }
  }, [dispatchFirstData?.recipientEmail]);

  const onFinishStepOne = async () => {
    try {
      await checkValidate();
      if (dispatchFirstData?.orderNumber === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          orderNumber: "Order number is required",
        }));
      } else if (dispatchFirstData?.orderNumber !== "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          orderNumber: "",
        }));
      }
      if (dispatchFirstData?.country === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          country: "Country is required",
        }));
      } else if (dispatchFirstData?.country !== "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          country: "",
        }));
      }
      if (dispatchFirstData?.item === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          item: "Item category is required",
        }));
      } else if (dispatchFirstData?.country !== "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          item: "",
        }));
      }
      if (dispatchFirstData?.dropOffZone === "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          dropOffZone: "Drop off zone is required",
        }));
      } else if (dispatchFirstData?.country !== "") {
        setInfoErrorMessages((prev) => ({
          ...prev,
          dropOffZone: "",
        }));
      }

      if (
        dispatchFirstData.dropOffZone !== "" &&
        dispatchFirstData.country !== "" &&
        dispatchFirstData.weight !== "" &&
        dispatchFirstData.weight !== "" &&
        dispatchFirstData.item !== "" &&
        dispatchFirstData.itemName !== "" &&
        dispatchFirstData.vendorName !== "" &&
        (dispatchFirstData.orderNumber !== "" ||
          dispatchFirstData.trackingId !== "") &&
        !isNaN(Number(dispatchFirstData.weight)) &&
        costError === "no" &&
        orderStatus !== false &&
        !isCostLoading
      ) {
        setStep(step + 1);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const onFinishStepTwo = async () => {
    try {
      await checkValidateTwo();
      if (
        dispatchFirstData.senderName !== "" &&
        dispatchFirstData.senderPhone !== "" &&
        !isNaN(Number(dispatchFirstData.senderPhone)) &&
        dispatchFirstData.senderEmail !== "" &&
        dispatchFirstData.recipientEmail !== "" &&
        dispatchFirstData.recipientName !== "" &&
        dispatchFirstData.recipientPhone !== "" &&
        !isNaN(Number(dispatchFirstData.recipientPhone)) &&
        dispatchFirstData.quantity !== "" &&
        !isNaN(Number(dispatchFirstData.quantity)) &&
        dispatchFirstData.itemDescription !== "" &&
        dispatchFirstData.cost !== "" &&
        infoErrorMessages.file === "" &&
        infoErrorMessages.recipientEmail === "" &&
        infoErrorMessages.senderEmail === "" &&
        !isNaN(Number(dispatchFirstData.cost))
      ) {
        setStep(step + 1);
      } else {
        console.log("err");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (termsChecked) {
      setInfoErrorMessages((prev) => ({
        ...prev,
        terms: "",
      }));
    }
  }, [termsChecked]);

  const updateReferalCode = async (orderNos: any) => {
    try {
      const body: RefProps = {
        orderNo: orderNos ? orderNos : "",
      };
      if (referralData?.status === "00" && referralData?.rate !== "") {
        body.referralCode = dispatchFirstData.referralCode;
        await updateReference(body)
          .unwrap()
          .then((data) => {
            console.log("data", data);
          })
          .catch((error: any) => {
            if (error?.data?.description) {
              toast.error(error?.data?.description, {
                className: "toast-error",
                progressClassName: "Toastify__progress-bar--error",
              });
            } else {
              toast.error("Network Error", {
                className: "toast-error",
                progressClassName: "Toastify__progress-bar--error",
              });
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onProceedToShipment = () => {
    if (termsChecked) {
      getMultipleData();
    } else {
      setInfoErrorMessages((prev) => ({
        ...prev,
        terms: "Terms and conditions is required!",
      }));
    }
  };

  useEffect(() => {
    if (step === 2 || step === 3) {
      scrollToTop();
    }
  }, [step]);

  useEffect(() => {
    if (
      multipleData &&
      Array.isArray(multipleData.orderNos) &&
      multipleData.orderNos[0]
    ) {
      updateReferalCode(multipleData.orderNos[0]);
      setShowSuccess(true);
    } else if (multipleData && dispatchFirstData?.orderNumber !== "") {
      setShowError(true);
    }
  }, [multipleData, dispatchFirstData?.orderNumber]);

  useEffect(() => {
    if (
      costData?.orderLocked === "YF" ||
      costData?.orderLocked === "YS" ||
      costData?.orderLocked === "Y" ||
      (costData?.status === "Error" && costData?.orderLocked === "Y") ||
      costData?.status === "Error" ||
      costData?.orderLocked === "D"
    ) {
      setShowOrderError(true);
    }
  }, [costData]);

  useEffect(() => {
    if (
      (costData?.orderLocked !== "YF" ||
        costData?.orderLocked !== "YS" ||
        costData?.orderLocked !== "Y") &&
      costData?.status === "Error" &&
      costData?.orderLocked !== "D"
    ) {
      setShowOrderErrorThree(true);
    }
  }, [costData]);

  useEffect(() => {
    if (
      costData?.orderLocked === "YF" ||
      costData?.orderLocked === "YS" ||
      costData?.orderLocked === "Y"
    ) {
      setShowOrderErrorOne(true);
    }
  }, [costData]);

  const handleCopy = (address: string) => {
    setCopiedAdd(address);
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setShowClipboard(true);

        setTimeout(() => {
          setShowClipboard(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Unable to copy to clipboard", err);
      });
  };

  return (
    <PageLayout>
      <div>
        <ToastContainer position="top-right" />
      </div>
      {step === 1 && (
        <div className=" mb-32 ">
          <div className="flex md:flex-row flex-col md:space-y-0 space-y-20 bg-[#EFF6EE] px-5 min-h-[700px] pt-24 sm:px-10 xl:px-20 md:space-x-5  md:items-center md:justify-between  ">
            <div className="animate__animated animate__fadeInTopLeft">
              <h2 className="xl:text-[69px] font-ppmigra lg:text-[60px] text-[40px] md:text-[50px] whitespace-nowrap text-textColor font-bold">
                Import Now
              </h2>
              <p className="text-[18px] xl:block hidden font-[500]">
                Our smart solution can help meet your delivery <br /> needs in a
                quick and convenient manner.
              </p>
              <p className="text-[18px] font-onestMedium xl:hidden block ">
                Our smart solution can help meet your delivery needs in a quick
                and convenient manner.
              </p>
            </div>
            <div className="animate__animated animate__fadeInTopRight">
              <img src="/images/all-car.svg" alt="" />
            </div>
          </div>
          <div className="mt-5  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
            <div className=" w-full ">
              <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
                Smart delivery solution
              </h3>
              <InformationLayout onClick={() => setShowTerms(true)} />
            </div>
            <div className="w-full ">
              <h4 className="text-textColor font-onestBold text-[20px]">
                Delivery details
              </h4>
              {errorCost !== "" && (
                <div className="bg-[#f19797] border-[red]  border max-w-[500px] px-5 py-2 rounded-md">
                  <p className="text-white items-center flex text-[14px]  font-onestMedium">
                    {errorCost}
                  </p>
                </div>
              )}
              <div className="mt-5 space-y-3 max-w-[500px]">
                <div>
                  <SelectInput
                    value={queryTwo}
                    name={`random_${Math.random()}`}
                    placeholder={"Select country"}
                    handleChange={(name, value) => handleCountryOption(value)}
                    label="Select Country"
                    dropdownOptions={locationData.map((location: any) => ({
                      label: location?.country?.name,
                      value: location?.country?.name,
                    }))}
                  />
                  {infoErrorMessages.country && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.country}
                    </p>
                  )}
                </div>
                <div className=" flex w-full items-center justify-between">
                  <div className="flex w-full space-y-1 flex-col ">
                    <h6 className="text-[#000000] items-start flex flex-col text-[14px]  font-onestMedium">
                      {showAddresses?.length > 0 && "Address:"}
                      {showAddresses?.addresses?.map(
                        (add: any, idx: number) => (
                          <div className="w-full mt-1" key={idx}>
                            <label
                              htmlFor={"state"}
                              className="text-[#000000] mb-1  items-center flex text-[14px]  font-onestMedium"
                            >
                              Address
                            </label>
                            <div className="flex space-x-3 mb-3  text-textColor p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2 ${className} border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full items-center">
                              <div
                                className="text-green-800  pl-1 flex flex-col text-[15px]"
                                key={idx}
                              >
                                {add?.address}
                              </div>
                              <div
                                onClick={() => handleCopy(add?.address)}
                                className="cursor-pointer"
                              >
                                {showClipboard && copiedAdd === add?.address ? (
                                  <div className="bg-black  whitespace-nowrap  h-fit w-fit px-3 py-1 rounded-[4px] text-white text-[8px]">
                                    <p> copied to clipboard</p>
                                  </div>
                                ) : (
                                  <img
                                    src="/images/copy.png"
                                    alt=""
                                    className="h-3 w-3"
                                  />
                                )}
                              </div>
                            </div>
                            <label
                              htmlFor={"state"}
                              className="text-[#000000] mb-1  items-center flex text-[14px]  font-onestMedium"
                            >
                              State
                            </label>
                            {showAddresses?.state && (
                              <div className="flex space-x-3 mb-3  text-textColor p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2 ${className} border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full items-center">
                                <div
                                  className="text-green-800  pl-1 flex flex-col text-[15px]"
                                  key={idx}
                                >
                                  {showAddresses?.state}
                                </div>
                                <div
                                  onClick={() =>
                                    handleCopy(showAddresses?.state)
                                  }
                                  className="cursor-pointer"
                                >
                                  {showClipboard &&
                                  copiedAdd === showAddresses?.state ? (
                                    <div className="bg-black  whitespace-nowrap  h-fit w-fit px-3 py-1 rounded-[4px] text-white text-[8px]">
                                      <p> copied to clipboard</p>
                                    </div>
                                  ) : (
                                    <img
                                      src="/images/copy.png"
                                      alt=""
                                      className="h-3 w-3"
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="flex w-full space-x-3 mb-1 justify-between">
                              <label
                                htmlFor={"state"}
                                className="text-[#000000]  w-full items-center flex text-[14px]  font-onestMedium"
                              >
                                City
                              </label>
                              <label
                                htmlFor={"state"}
                                className="text-[#000000] w-full items-start flex text-[14px]  font-onestMedium"
                              >
                                Post Code
                              </label>
                            </div>
                            <div className="flex  space-x-3 w-full">
                              {showAddresses?.city && (
                                <div className="flex  mb-3 space-x-3 text-textColor p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2 ${className} border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full items-center">
                                  <div
                                    className="text-green-800  pl-1 flex flex-col text-[15px]"
                                    key={idx}
                                  >
                                    {showAddresses?.city}
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleCopy(showAddresses?.city)
                                    }
                                    className="cursor-pointer"
                                  >
                                    {showClipboard &&
                                    copiedAdd === showAddresses?.city ? (
                                      <div className="bg-black  whitespace-nowrap  h-fit w-fit px-3 py-1 rounded-[4px] text-white text-[8px]">
                                        <p> copied to clipboard</p>
                                      </div>
                                    ) : (
                                      <img
                                        src="/images/copy.png"
                                        alt=""
                                        className="h-3 w-3"
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                              {showAddresses?.postCode && (
                                <div className="flex space-x-3 mb-3  text-textColor p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border-2 ${className} border-inputBorderGreen  focus:border-2  focus:border-border-2 focus:border-inputBorderGreen rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full items-center">
                                  <div
                                    className="text-green-800  pl-1 flex flex-col text-[15px]"
                                    key={idx}
                                  >
                                    {showAddresses?.postCode}
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleCopy(showAddresses?.postCode)
                                    }
                                    className="cursor-pointer"
                                  >
                                    {showClipboard &&
                                    copiedAdd === showAddresses?.postCode ? (
                                      <div className="bg-black  whitespace-nowrap  h-fit w-fit px-3 py-1 rounded-[4px] text-white text-[8px]">
                                        <p> copied to clipboard</p>
                                      </div>
                                    ) : (
                                      <img
                                        src="/images/copy.png"
                                        alt=""
                                        className="h-3 w-3"
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </h6>
                  </div>
                </div>
                <p className="text-textGreen font-onestBold text-[16px] ">
                  SHOPPER INFORMATION
                </p>
                <div>
                  <p className="text-sm font-onestRegular text-gray-500">
                    Please enter the Tracking ID for accurate tracking
                  </p>
                  <Input
                    id="orderNumberInput"
                    type="text"
                    value={dispatchFirstData?.orderNumber}
                    onChange={handleChange}
                    label="Tracking ID"
                    name="orderNumber"
                    placeholder="Enter tracking id"
                    labelFor="orderNumber"
                    isRequired={true}
                  />
                  {infoErrorMessages.orderNumber && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.orderNumber}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    value={dispatchFirstData?.vendorName}
                    onChange={handleChange}
                    label=" Vendor/Business Name"
                    name="vendorName"
                    placeholder="eg: Zara, ASOS, TESCO, next, MATALAN"
                    labelFor="vendorName"
                    isRequired
                  />
                  {infoErrorMessages.vendorName && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.vendorName}
                    </p>
                  )}
                </div>
                <div>
                  <SelectInput
                    value={query}
                    name="item"
                    placeholder={"Select Item Category"}
                    handleChange={(name, value) => handleItemOption(value)}
                    label={"Select Item Category"}
                    dropdownOptions={itemCategory.map((item: any) => ({
                      label: item?.name,
                      value: item?.name,
                    }))}
                  />
                  {infoErrorMessages.item && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.item}
                    </p>
                  )}
                </div>
                <div>
                  <SearchableDropdown
                    lists={filteredDropOffOptions}
                    handleOptionClick={handleOptionClickTwo}
                    onFocus={focusHandleDropOff}
                    title="Select Drop-off Zone"
                    label={"Drop Zone"}
                    isOpen={isOpenThree}
                    name="dropOffZone"
                    setIsOpen={setIsOpenThree}
                    setQuery={setQueryThree}
                    query={queryThree}
                    isRequired
                    queryDeliverytype="local"
                    setZoneOption={setZoneOptionThree}
                    toggleDropdown={() => setIsOpenThree(!isOpenThree)}
                  />
                  {infoErrorMessages.dropOffZone && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.dropOffZone}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    value={dispatchFirstData?.dropOffAddress}
                    onChange={handleChange}
                    label="Drop-off Address "
                    name="dropOffAddress"
                    placeholder="Drop off address"
                    labelFor="dropoffAddress"
                    isRequired
                  />
                  {infoErrorMessages.dropOffAddress && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.dropOffAddress}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    value={dispatchFirstData?.itemName}
                    onChange={handleChange}
                    label=" Item Name"
                    name="itemName"
                    placeholder="Item name"
                    labelFor="itemName"
                    isRequired
                  />
                  {infoErrorMessages.itemName && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.itemName}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    label="Weight"
                    placeholder=""
                    className="w-full"
                    weightShow
                    name="weight"
                    isRequired
                    value={dispatchFirstData.weight}
                    onChange={handleChange}
                  />
                  {infoErrorMessages.weight && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.weight}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-10 max-w-[500px] ">
                <Button
                  title="Next"
                  className="!py-3"
                  onClick={onFinishStepOne}
                  disabled={isCostLoading}
                  loading={false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className=" mb-32 ">
          <SectionBack
            imgStr="/images/all-car.svg"
            onClick={() => setStep(step - 1)}
            content=" Import Now"
          />
          <div className="mt-10  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
            <div className=" w-full ">
              <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
                Smart delivery solution
              </h3>
              <InformationLayout onClick={() => setShowTerms(true)} />
            </div>
            <div className="w-full ">
              {errorCost !== "" && (
                <div className="bg-[#f19797] border-[red]  border max-w-[500px] px-5 py-2 rounded-md">
                  <p className="text-white items-center flex text-[14px]  font-onestMedium">
                    {errorCost}
                  </p>
                </div>
              )}
              <div className="mt-2 space-y-3 max-w-[500px]">
                <p className="text-textGreen font-onestBold text-[16px] mt-3">
                  SENDERS INFORMATION
                </p>
                <div>
                  <Input
                    type="text"
                    name="senderName"
                    label="Sender's Name"
                    placeholder="Enter name"
                    labelFor="sendersName"
                    value={dispatchFirstData?.senderName}
                    isRequired
                    onChange={handleChangeTwo}
                  />
                  {infoErrorMessages.senderName && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.senderName}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    value={dispatchFirstData?.senderPhone}
                    name="senderPhone"
                    label="Sender’s Phone number"
                    placeholder="Enter phone number"
                    labelFor="phoneNumber"
                    onChange={handleChangeTwo}
                    isRequired
                  />
                  {infoErrorMessages.senderPhone && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.senderPhone}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    value={dispatchFirstData?.senderAltPhone}
                    name="senderAltPhone"
                    label="Sender’s Alternative Phone number"
                    placeholder="Enter phone number"
                    labelFor="phoneNumber"
                    onChange={handleChangeTwo}
                  />
                  {infoErrorMessages.senderAltPhone && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.senderAltPhone}
                    </p>
                  )}
                </div>

                <div>
                  <Input
                    type="email"
                    name="senderEmail"
                    value={dispatchFirstData?.senderEmail}
                    label="Sender’s Email"
                    placeholder="Enter email address"
                    labelFor="senderEmail"
                    onChange={handleChangeTwo}
                    isRequired
                  />
                  {infoErrorMessages.senderEmail && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.senderEmail}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-8 space-y-3 max-w-[500px]">
                <p className="text-textGreen font-onestBold text-[16px] ">
                  RECIPIENT INFORMATION
                </p>

                <div>
                  <Input
                    name="recipientName"
                    type="text"
                    label="Recipient's Name"
                    value={dispatchFirstData?.recipientName}
                    placeholder="Enter name"
                    labelFor="recipientName"
                    onChange={handleChangeTwo}
                    isRequired
                  />
                  {infoErrorMessages.recipientName && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.recipientName}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    name="recipientEmail"
                    type="email"
                    label="Recipient's Email"
                    isRequired
                    value={dispatchFirstData?.recipientEmail}
                    placeholder="Enter email"
                    labelFor="recipientEmail"
                    onChange={handleChangeTwo}
                  />
                  {infoErrorMessages.recipientEmail && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.recipientEmail}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    value={dispatchFirstData?.recipientPhone}
                    name="recipientPhone"
                    label="Recipient's Phone number"
                    placeholder="Enter phone number"
                    labelFor="recipientPhone"
                    isRequired
                    onChange={handleChangeTwo}
                  />
                  {infoErrorMessages.recipientPhone && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.recipientPhone}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-8 space-y-3 max-w-[500px]">
                <p className="text-textGreen font-onestBold text-[16px] ">
                  PACKAGE DETAILS
                </p>

                <div>
                  <TextArea
                    label="Item Description"
                    placeholder="Enter description"
                    labelFor="description"
                    name="itemDescription"
                    value={dispatchFirstData?.itemDescription}
                    onChange={handleChangeTwo}
                    isRequired
                  />
                  {infoErrorMessages.itemDescription && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.itemDescription}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    name="quantity"
                    type="text"
                    label="Quantity"
                    value={dispatchFirstData?.quantity}
                    placeholder="Enter quantity"
                    labelFor="quantity"
                    isRequired
                    onChange={handleChangeTwo}
                  />
                  {infoErrorMessages.quantity && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.quantity}
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    name="cost"
                    label={`Value of Item in  ${currencyCode}`}
                    placeholder="Enter value"
                    labelFor="cost"
                    isRequired
                    value={dispatchFirstData?.cost}
                    onChange={handleChangeTwo}
                  />
                  {infoErrorMessages.cost && (
                    <p className="text-red-500 text-[12px]">
                      {infoErrorMessages.cost}
                    </p>
                  )}
                </div>
                {Number(dispatchFirstData?.cost) >=
                  Number(dispatchFirstData?.importAddress) && (
                  <div>
                    <Input
                      type="file"
                      name="invoice"
                      label="Invoice"
                      labelFor="Invoice"
                      isRequired
                      accept=".jpg, .jpeg, .png, .pdf"
                      placeholder={dispatchFirstData.fileNames}
                      onChange={handleFileChange}
                      file={dispatchFirstData.fileNames}
                    />
                    {infoErrorMessages.file && (
                      <p className="text-red-500 text-[12px]">
                        {infoErrorMessages.file}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="mt-10 space-y-2 max-w-[500px] ">
                <Button
                  title="Next"
                  className="!py-3"
                  onClick={onFinishStepTwo}
                  loading={false}
                />
                <Button
                  title="Back"
                  className="!py-3 !bg-transparent"
                  onClick={() => setStep(step - 1)}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className=" mb-32 ">
          <SectionBack
            imgStr="/images/all-car.svg"
            onClick={() => setStep(step - 1)}
            content=" Import Now"
          />
          <div className="mt-10  w-full px-5 sm:px-10 xl:px-20 md:flex-row flex-col md:space-y-0 space-y-5   flex md:justify-between ">
            <div className=" w-full ">
              <h3 className="md:text-[32px] text-[24px] font-ppmigra text-textColor">
                Smart delivery solution
              </h3>
              <InformationLayout onClick={() => setShowTerms(true)} />
            </div>
            <div className="w-full ">
              {errorCost !== "" && (
                <div className="bg-[#f19797] border-[red]  border max-w-[500px] px-5 py-2 rounded-md">
                  <p className="text-white items-center flex text-[14px]  font-onestMedium">
                    {errorCost}
                  </p>
                </div>
              )}
              <div className="mt-3 space-y-3 max-w-[500px]">
                <p className="text-textGreen font-onestBold text-[16px] ">
                  TOTAL COST
                </p>
                <p className="font-onestRegular text-[14px] md:text-[15px]">
                  The total estimated cost for your delivery is
                </p>
                <div className="border rounded-[8px] px-3 py-3 border-[#C2C5C2] shadow  drop-shadow-2xl  shadow-shadow">
                  {referralData?.status === "00" &&
                  referralData?.rate !== "" ? (
                    <div className="flex space-x-3">
                      <h2
                        className={`line-through text-red-500 font-onestBold text-[28px] md:text-[34px]`}
                      >
                        {` ${getSymbolFromCurrency(
                          currencyCode
                        )} ${new Intl.NumberFormat().format(
                          Number(costData?.rate)
                        )}`}
                      </h2>
                      <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                        {` ${getSymbolFromCurrency(
                          currencyCode
                        )} ${new Intl.NumberFormat().format(
                          Number(referralData?.rate)
                        )}`}
                      </h2>
                    </div>
                  ) : (
                    <h2 className="font-onestBold text-textGreen text-[28px] md:text-[34px]">
                      {getSymbolFromCurrency(currencyCode)}{" "}
                      {new Intl.NumberFormat().format(Number(costData?.rate))}
                    </h2>
                  )}
                </div>
              </div>
              <div className="flex max-w-[500px] mt-5 items-center space-x-2">
                <Input
                  type="text"
                  value={dispatchFirstData.referralCode}
                  onChange={handleChange}
                  label="Referral Code"
                  name="referralCode"
                  placeholder="Enter referral code"
                  labelFor="referralCode"
                />
                <Button
                  title="Apply"
                  loading={isReferralLoading}
                  className="mt-5 border border-btnColor !bg-[#E8FFE5]"
                  onClick={() => {
                    setApplyReferral(true);
                    getCostReferralData();
                  }}
                />
              </div>
              {referralData?.status === "00" && (
                <p className="text-green-600 text-[12px]">
                  Congratulations! Your referral code is valid.
                </p>
              )}
              {referralData?.status === "01" && (
                <p className="text-red-500 text-[12px]">
                  An error occurred while processing your referral code.
                </p>
              )}
              {referralData?.status === "02" && (
                <p className="text-red-500 text-[12px]">
                  The referral code you used has expired. Please try a different
                  one.
                </p>
              )}
              {referralData?.status === "03" && (
                <p className="text-red-500 text-[12px]">
                  Oops! The referral code you entered is not valid
                </p>
              )}

              {referralData?.status === "04" && (
                <p className="text-red-500 text-[12px]">
                  The order limit for the referral code has been reached
                </p>
              )}
              {referralData?.status === "05" && (
                <p className="text-red-500 text-[12px]">
                  Oops! This referral code is not currently active.
                </p>
              )}
              <div className="mt-3 flex space-x-1">
                <input
                  type="checkbox"
                  required={true}
                  className="cursor-pointer"
                  onChange={(e: any) => setTermsChecked(e.target.checked)}
                />

                <div>
                  <p className="text-[14px] font-onest text-grey">
                    By ticking the checkbox, you have accepted our
                    <span
                      className="underline text-textColor ml-1 cursor-pointer"
                      onClick={() => setShowTerms(true)}
                    >
                      Terms and Conditions.
                    </span>
                  </p>
                </div>
              </div>
              {infoErrorMessages.terms && (
                <p className="text-red-500 text-[12px]">
                  {infoErrorMessages.terms}
                </p>
              )}
              <div className="mt-3 space-y-2 max-w-[500px] ">
                <Button
                  title="Continue with your Shipment"
                  className="!py-3"
                  loading={isMultipleLoading}
                  onClick={onProceedToShipment}
                />
                <Button
                  title="Back"
                  disabled={isMultipleLoading}
                  className="!py-3 !bg-transparent"
                  onClick={() => setStep(step - 1)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showSuccess && (
        <DashboardModalAction
          contentTitle={"Success! ✅ "}
          content={`Your order has been created.
          Expect an email notification once we receive the package. 
          Here is your Order ID 
          `}
          btnContent="Close"
          trackNo={
            multipleData &&
            multipleData.orderNos &&
            Array.isArray(multipleData.orderNos) &&
            multipleData.orderNos[0]
          }
          onClick={() => window.location.reload()}
        />
      )}

      {showOrderError && (
        <ShowOrderLockYF onClick={() => setShowOrderError(false)} />
      )}

      {showOrderErrorOne && (
        <ShowOrderErrorandD onClick={() => setShowOrderErrorOne(false)} />
      )}

      {showOrderErrorThree && (
        <ShowErrorandYS onClick={() => setShowOrderErrorThree(false)} />
      )}
      {showTerms && (
        <ShowTermsModal
          onClick={() => setShowTerms(false)}
          terms={devTerms}
          id="formattedText"
        />
      )}
      {multipleError && (
        <ErrorModal
          errorMessage={"Network Error, Please, try again! "}
          onClick={() => location.reload()}
        />
      )}
    </PageLayout>
  );
};

export default Import;
