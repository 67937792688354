import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const termsApi = createApi({
  reducerPath: "termsApi",
  baseQuery: fetchBaseQuery({
    baseUrl:   `${process.env.REACT_APP_URL}`,
    credentials: "same-origin",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTerms: builder.query<any, void>({
      query: () => ({
        url: `v3/fetchTC`,
        method: 'GET',
      }),
    }),
   
  }),
});

export const { useGetTermsQuery } = termsApi;
